import React, {useState} from "react";
import Config from "../../config";
import {
    Box,
    createStyles,
    IconButton,
    makeStyles,
    Theme,
    TextField,
    Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {ThinkTankKeyword} from "../../models/models";
//import ReactHtmlParser from 'react-html-parser';
import parse from 'html-react-parser';
import {v4 as uuid} from "uuid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      flex: 1,
      display: "flex",
      alignItems: "center",
    },
    icon: {
      color: theme.palette.text.primary,
    },
    thinkTankKeywordContainer: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: 'transparent',
      borderRadius: 10,
      marginTop: 2,
      marginBottom: 2,
      marginRight: 15,
    },
    thinkTankKeyword: {
      marginTop: 2,
      marginBottom: 2,
      marginRight: 5,
      border: "2px solid grey",
      borderRadius: 10,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
      paddingBottom: 3,
    }
  })
);



export const ThinkTankKeywordItemNew: React.FC<{
  parentThinkTank: ThinkTankContent
  updateParentThinkTank: (thinkTankKeyword: ThinkTankKeyword) =>  void
}> = ( {
  parentThinkTank,
  updateParentThinkTank
}) => {

  const classes = useStyles();

  //status = 3
  const registrationId = Config.getInstance().getRegistrationID();
  const [newKeyword, setNewKeyword] = useState<ThinkTankKeyword>(new ThinkTankKeyword("", uuid(), "", registrationId, true, false, 3));

  return (
       <Box className={classes.thinkTankKeywordContainer}
            key={newKeyword.dbId}
       >
          <TextField
            size="small"
            label="New Keyword (max. 25)"
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <IconButton onClick={() => {const updatedNewKeyword = {...newKeyword,
                                                                       readOnly: true};
                                            updateParentThinkTank(updatedNewKeyword);
                                            setNewKeyword(new ThinkTankKeyword("", uuid(), "", registrationId, true, false, 3))}}>
                  <AddIcon/>
                </IconButton>
              ),
            }}
            inputProps={{
              maxLength: 25,
            }}
            className={classes.textField}
            value={newKeyword?.title}
            onChange={(event) => setNewKeyword({
                    ...newKeyword,
                    title: event.target.value
                  })}
          />
      </Box>
  );
};


export const ThinkTankKeywordItem: React.FC<{
  thinkTankKeyword: ThinkTankKeyword,
}> = ( {
  thinkTankKeyword,
}) => {

  const classes = useStyles();

  return (
       <Box className={classes.thinkTankKeywordContainer}
            key={thinkTankKeyword.id}
       >
         <Typography className={classes.thinkTankKeyword} color="textSecondary">
           {thinkTankKeyword.title ? parse(thinkTankKeyword.title) : thinkTankKeyword.title}
         </Typography>
      </Box>
  );
};
