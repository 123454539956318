export interface BaseJSON {
  id: string;
}

export abstract class BaseModel {
  id: string;
  origin: string;

  /**
   * Constructor of the Basemodel class
   * @param id database id of the object
     @param origin origin of the API calls
   */
   /*note: since the same backend API is called also in event dashboard
   when updating a registration, we need to specify the origin where the request comes from
   */

  protected constructor(id: string, origin='participant_dashboard') {
    this.id = id;
    this.origin = origin;
  }
}




export interface RegistrationJSON extends BaseJSON {
  name: string;
  title: string;
  type: string;
  company: string;
  contact: string;
  business_partner: string;
  participation_status: string;
  portal_access: string;
  linkedin_url: string;
  think_round1: boolean;
  think_round2: boolean;
  dinner_round1: boolean;
  dinner_round2: boolean;
  booked_branding: boolean;
  booked_dinner_hosting: boolean;
  booked_think_tank_suggestion: boolean;
  booked_think_tank_selection: boolean;
  booked_think_tank_id: string;
  priorities: string[];
  optimal_seatings: string[];
  optimal_dinner_seatings: number[];
  origin: string;
}

export class Registration extends BaseModel {
  name: string;
  title: string;
  type: string;
  company: string;
  contact: string;
  businessPartner: string;
  participationStatus: string;
  portalAccess: string;
  linkedinUrl: string;
  thinkRound1: boolean;
  thinkRound2: boolean;
  dinnerRound1: boolean;
  dinnerRound2: boolean;
  bookedBranding: boolean;
  bookedDinnerHosting: boolean;
  bookedThinkTankSuggestion: boolean;
  bookedThinkTankSelection: boolean;
  bookedThinkTankId: string;
  priorities: string[];
  optimalSeatings: string[];
  optimalDinnerSeatings: number[];

  constructor(
    id: string,
    name: string,
    title: string,
    type: string,
    company: string,
    contact: string,
    businessPartner: string,
    participationStatus: string,
    portalAccess: string,
    linkedinUrl: string,
    thinkRound1: boolean,
    thinkRound2: boolean,
    dinnerRound1: boolean,
    dinnerRound2: boolean,
    bookedBranding: boolean,
    bookedDinnerHosting: boolean,
    bookedThinkTankSuggestion: boolean,
    bookedThinkTankSelection: boolean,
    bookedThinkTankId: string,
    priorities: string[],
    optimalSeatings: string[],
    optimalDinnerSeatings: number[]
  ) {
    super(id);

    this.name = name;
    this.title = title;
    this.type = type;
    this.company = company;
    this.contact = contact;
    this.businessPartner = businessPartner;
    this.participationStatus = participationStatus;
    this.portalAccess = portalAccess;
    this.linkedinUrl = linkedinUrl;
    this.thinkRound1 = thinkRound1;
    this.thinkRound2 = thinkRound2;
    this.dinnerRound1 = dinnerRound1;
    this.dinnerRound2 = dinnerRound2;
    this.bookedBranding = bookedBranding;
    this.bookedDinnerHosting = bookedDinnerHosting;
    this.bookedThinkTankSuggestion = bookedThinkTankSuggestion;
    this.bookedThinkTankSelection = bookedThinkTankSelection;
    this.bookedThinkTankId = bookedThinkTankId;
    this.priorities = priorities;
    this.optimalSeatings = optimalSeatings;
    this.optimalDinnerSeatings = optimalDinnerSeatings;
  }
}

export class RegistrationFactory {
  /**
   *
   * @param json
   */
  fromJSON(json: RegistrationJSON): Registration {
    return new Registration(
      json.id,
      json.name,
      json.title,
      json.type,
      json.company,
      json.contact,
      json.business_partner,
      json.participation_status,
      json.portal_access,
      json.linkedin_url,
      json.think_round1,
      json.think_round2,
      json.dinner_round1,
      json.dinner_round2,
      json.booked_branding,
      json.booked_dinner_hosting,
      json.booked_think_tank_suggestion,
      json.booked_think_tank_selection,
      json.booked_think_tank_id,
      json.priorities,
      json.optimal_seatings,
      json.optimal_dinner_seatings
    );
  }

  init(): Registration {
    return new Registration(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      "",
      [],
      [],
      [],
    )


  }

  /**
   *
   * @param contact
   */
  toJSON(registration: Registration): RegistrationJSON {
    return {
      id: registration.id,
      name: registration.name,
      title: registration.title,
      type: registration.type,
      company: registration.company,
      contact: registration.contact,
      business_partner: registration.businessPartner,
      participation_status: registration.participationStatus,
      portal_access: registration.portalAccess,
      linkedin_url: registration.linkedinUrl,
      think_round1: registration.thinkRound1,
      think_round2: registration.thinkRound2,
      dinner_round1: registration.dinnerRound1,
      dinner_round2: registration.dinnerRound2,
      booked_branding: registration.bookedBranding,
      booked_dinner_hosting: registration.bookedDinnerHosting,
      booked_think_tank_suggestion: registration.bookedThinkTankSuggestion,
      booked_think_tank_selection: registration.bookedThinkTankSelection,
      booked_think_tank_id: registration.bookedThinkTankId,
      priorities: registration.priorities,
      optimal_seatings: registration.optimalSeatings,
      optimal_dinner_seatings: registration.optimalDinnerSeatings,
      origin: registration.origin,
    };
  }
}

export interface ProfileJSON extends BaseJSON {
  registration_id: string;
  registration_type: string;
  description: string;
  dietary_needs: string;
  email: string;
  address: string;
  linkedin_url: string;
  phone_number: string;
  mobile_phone_number: string;
  allow_email_sharing: boolean;
  allow_phone_sharing: boolean;
  allow_dietary_needs_sharing: boolean;
  accept_legal_notice: boolean;
  feedback_networking_opportunities: number;
  feedback_executive_interactions: number;
  feedback_topics_contents: number;
  feedback_location_food: number;
  feedback_overall_experience: number
  feedback_interested_in_upcoming_event: string;
  feedback_additional: string;
}

export class Profile extends BaseModel {
  registrationID: string;
  registrationType: string;
  description: string;
  dietaryNeeds: string;
  email: string;
  address: string;
  linkedinUrl: string;
  phoneNumber: string;
  mobilePhoneNumber: string;
  allowEmailSharing: boolean;
  allowPhoneSharing: boolean;
  allowDietaryNeedsSharing: boolean;
  acceptLegalNotice: boolean;
  feedbackNetworkingOpportunities: number;
  feedbackExecutiveInteractions: number;
  feedbackTopicsContents: number;
  feedbackLocationFood: number;
  feedbackOverallExperience: number
  feedbackInterestedInUpcomingEvent: string;
  feedbackAdditional: string;

  constructor(
    id: string,
    registrationID: string,
    registrationType: string,
    description: string,
    dietaryNeeds: string,
    email: string,
    address: string,
    linkedinUrl: string,
    phoneNumber: string,
    mobilePhoneNumber: string,
    allowEmailSharing: boolean,
    allowPhoneSharing: boolean,
    allowDietaryNeedsSharing: boolean,
    acceptLegalNotice: boolean,
    feedbackNetworkingOpportunities: number,
    feedbackExecutiveInteractions: number,
    feedbackTopicsContents: number,
    feedbackLocationFood: number,
    feedbackOverallExperience: number,
    feedbackInterestedInUpcomingEvent: string,
    feedbackAdditional: string
  ) {
    super(id);

    this.registrationID = registrationID;
    this.registrationType = registrationType;
    this.description = description;
    this.dietaryNeeds = dietaryNeeds;
    this.email = email;
    this.address = address;
    this.linkedinUrl = linkedinUrl;
    this.phoneNumber = phoneNumber;
    this.mobilePhoneNumber = mobilePhoneNumber;
    this.allowEmailSharing = allowEmailSharing;
    this.allowPhoneSharing = allowPhoneSharing;
    this.allowDietaryNeedsSharing = allowDietaryNeedsSharing;
    this.acceptLegalNotice = acceptLegalNotice;
    this.feedbackNetworkingOpportunities = feedbackNetworkingOpportunities;
    this.feedbackExecutiveInteractions = feedbackExecutiveInteractions;
    this.feedbackTopicsContents = feedbackTopicsContents;
    this.feedbackLocationFood = feedbackLocationFood;
    this.feedbackOverallExperience = feedbackOverallExperience;
    this.feedbackInterestedInUpcomingEvent = feedbackInterestedInUpcomingEvent;
    this.feedbackAdditional = feedbackAdditional;
  }
}

export class ProfileFactory {
  fromJSON(json: ProfileJSON): Profile {
    return new Profile(
      json.id,
      json.registration_id,
      json.registration_type,
      json.description,
      json.dietary_needs,
      json.email,
      json.address,
      json.linkedin_url,
      json.phone_number,
      json.mobile_phone_number,
      json.allow_email_sharing,
      json.allow_phone_sharing,
      json.allow_dietary_needs_sharing,
      json.accept_legal_notice,
      json.feedback_networking_opportunities,
      json.feedback_executive_interactions,
      json.feedback_topics_contents,
      json.feedback_location_food,
      json.feedback_overall_experience,
      json.feedback_interested_in_upcoming_event,
      json.feedback_additional
    );
  }

  toJSON(profile: Profile): ProfileJSON {
    return {
      id: profile.id,
      registration_id: profile.registrationID,
      registration_type: profile.registrationType,
      description: profile.description,
      dietary_needs: profile.dietaryNeeds,
      email: profile.email,
      address: profile.address,
      linkedin_url: profile.linkedinUrl,
      phone_number: profile.phoneNumber,
      mobile_phone_number: profile.mobilePhoneNumber,
      allow_email_sharing: profile.allowEmailSharing,
      allow_phone_sharing: profile.allowPhoneSharing,
      allow_dietary_needs_sharing: profile.allowDietaryNeedsSharing,
      accept_legal_notice: profile.acceptLegalNotice,
      feedback_networking_opportunities: profile.feedbackNetworkingOpportunities,
      feedback_executive_interactions: profile.feedbackExecutiveInteractions,
      feedback_topics_contents: profile.feedbackTopicsContents,
      feedback_location_food: profile.feedbackLocationFood,
      feedback_overall_experience: profile.feedbackOverallExperience,
      feedback_interested_in_upcoming_event: profile.feedbackInterestedInUpcomingEvent,
      feedback_additional: profile.feedbackAdditional
    };
  }

  init(): Profile {
    return new Profile(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      false,
      false,
      false,
      false,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
  }


}

export interface EventJSON extends BaseJSON {
  name: string;
  description: string;
  venue: string;
  venue_name: string;
  venue_city: string;
  venue_country: string;
  venue_image: string;
  venue_postal_code: string;
  venue_status: string;
  venue_street_address_1: string;
  venue_street_address_2: string;
  room_name: string;
  event_date: string;
}

export class Event extends BaseModel {
  name: string;
  description: string;
  venue: string;
  venueName: string;
  venueCity: string;
  venueCountry: string;
  venueImage: string;
  venuePostalCode: string;
  venueStatus: string;
  venueStreetAddress1: string;
  venueStreetAddress2: string;
  roomName: string;
  eventDate: string;

  constructor(
    id: string,
    name: string,
    description: string,
    venue: string,
    venueName: string,
    venueCity: string,
    venueCountry: string,
    venueImage: string,
    venuePostalCode: string,
    venueStatus: string,
    venueStreetAddress1: string,
    venueStreetAddress2: string,
    roomName: string,
    eventDate: string
  ) {
    super(id);

    this.name = name;
    this.description = description;
    this.venue = venue;
    this.venueName = venueName;
    this.venueCity = venueCity;
    this.venueCountry = venueCountry;
    this.venueImage = venueImage;
    this.venuePostalCode = venuePostalCode;
    this.venueStatus = venueStatus;
    this.venueStreetAddress1 = venueStreetAddress1;
    this.venueStreetAddress2 = venueStreetAddress2;
    this.roomName = roomName;
    this.eventDate = eventDate;
  }
}

export class EventFactory {
  /**
   *
   * @param json
   */
  fromJSON(json: EventJSON): Event {
    return new Event(
      json.id,
      json.name,
      json.description,
      json.venue,
      json.venue_name,
      json.venue_city,
      json.venue_country,
      json.venue_image,
      json.venue_postal_code,
      json.venue_status,
      json.venue_street_address_1,
      json.venue_street_address_2,
      json.room_name,
      json.event_date
    );
  }

  /**
   *
   * @param contact
   */
  toJSON(event: Event): EventJSON {
    return {
      id: event.id,
      name: event.name,
      description: event.description,
      venue: event.venue,
      venue_name: event.venueName,
      venue_city: event.venueCity,
      venue_country: event.venueCountry,
      venue_image: event.venueImage,
      venue_postal_code: event.venuePostalCode,
      venue_status: event.venueStatus,
      venue_street_address_1: event.venueStreetAddress1,
      venue_street_address_2: event.venueStreetAddress2,
      room_name: event.roomName,
      event_date: event.eventDate,
    };
  }


  init(): Event {
    return new Event(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    )
  }

}

export interface ThinkTankKeywordJSON extends BaseJSON {
  db_id: string;
  title: string;
  submitted_by_registration_id: string;
  active: boolean;
  read_only: boolean;
  status: number;
}

export class ThinkTankKeyword extends BaseModel {
  dbId: string;
  title: string;
  submittedByRegistrationId: string;
  active: boolean;
  readOnly: boolean;
  status: number;

  constructor(
      id: string,
      dbId: string,
      title: string,
      submittedByRegistrationId: string,
      active: boolean,
      readOnly: boolean,
      status: number,
  ) {
    super(id);
    this.dbId = dbId;
    this.title = title;
    this.submittedByRegistrationId = submittedByRegistrationId;
    this.active = active;
    this.readOnly = readOnly;
    this.status = status;
  }
}

export interface ThinkTankContentJSON extends BaseJSON {
  db_id: string;
  title: string;
  content_en: string;
  submitted_by_registration_id: string;
  active: boolean;
  read_only: boolean;
  status: number;
  weight: number;
  keywords: ThinkTankKeywordJSON [];
}

export class ThinkTankContent extends BaseModel {
  dbId: string;
  title: string;
  contentEn: string;
  submittedByRegistrationId: string;
  active: boolean;
  readOnly: boolean;
  status: number;
  weight: number;
  keywords: ThinkTankKeyword[];

  constructor(
      id: string,
      dbId: string,
      title: string,
      contentEn: string,
      submittedByRegistrationId: string,
      active: boolean,
      readOnly: boolean,
      status: number,
      weight: number,
      keywords: ThinkTankKeyword[],
  ) {
    super(id);
    this.dbId = dbId;
    this.title = title;
    this.contentEn = contentEn;
    this.submittedByRegistrationId = submittedByRegistrationId;
    this.active = active;
    this.readOnly = readOnly;
    this.status = status;
    this.weight = weight;
    this.keywords = keywords;
  }
}

export interface ThinkTankDynamicContentJSON extends BaseJSON {
  event_id: string;
  registration_id: string;
  real_table_id: string;
  real_table_sf_id: string;
  topic: string;
  weight: number;
}

export class ThinkTankDynamicContent extends BaseModel {
  eventId: string;
  registrationId: string;
  realTableId: string;
  realTableSfId: string;
  topic: string;
  weight:number;

  constructor(
    id: string,
    eventId: string,
    registrationId: string,
    realTableId: string,
    realTableSfId: string,
    topic: string,
    weight: number
  ) {
    super(id);

    this.eventId = eventId;
    this.registrationId = registrationId;
    this.realTableId = realTableId;
    this.realTableSfId = realTableSfId;
    this.topic = topic;
    this.weight = weight;
  }
}

export class ThinkTankDynamicContentFactory {
  fromJSON(json: ThinkTankDynamicContentJSON): ThinkTankDynamicContent {
    return new ThinkTankDynamicContent(
      json.id,
      json.event_id,
      json.registration_id,
      json.real_table_id,
      json.real_table_sf_id,
      json.topic,
      json.weight
    );
  }

  toJSON(thinkTankDynamicContent: ThinkTankDynamicContent): ThinkTankDynamicContentJSON {
    return {
      id: thinkTankDynamicContent.id,
      event_id: thinkTankDynamicContent.eventId,
      registration_id: thinkTankDynamicContent.registrationId,
      real_table_id: thinkTankDynamicContent.realTableId,
      real_table_sf_id: thinkTankDynamicContent.realTableSfId,
      topic: thinkTankDynamicContent.topic,
      weight: thinkTankDynamicContent.weight
    };
  }

  init(eventId: string,
       registrationId: string,
       realTableId: string,
       realTableSfId: string,
  ): ThinkTankDynamicContent {
    return new ThinkTankDynamicContent(
      "",
      eventId,
      registrationId,
      realTableId,
      realTableSfId,
      "",
      0
    )
  }
}


export interface ThinkTankPriorityJSON extends BaseJSON {
  registration_id: string;
  parent_think_tank_db_id: string;
  think_tank_db_id: string;
  priority: number;
}

export class ThinkTankPriority extends BaseModel {
  registrationId: string;
  parentThinkTankDbId: string;
  thinkTankDbId: string;
  priority: number;

  constructor(
      id: string,
      registrationId: string,
      parentThinkTankDbId: string,
      thinkTankDbId: string,
      priority: number
  ) {
    super(id);

    this.registrationId = registrationId;
    this.parentThinkTankDbId = parentThinkTankDbId;
    this.thinkTankDbId = thinkTankDbId;
    this.priority = priority;
  }
}

export class ThinkTankPriorityFactory {
  /**
   *
   * @param json
   */
  fromJSON(json: ThinkTankPriorityJSON): ThinkTankPriority {
    return new ThinkTankPriority(
      json.id,
      json.registration_id,
      json.parent_think_tank_db_id,
      json.think_tank_db_id,
      json.priority
    );
  }

  toJSON(thinkTankPriority: ThinkTankPriority): ThinkTankPriorityJSON {
    return {
      id: thinkTankPriority.id,
      registration_id: thinkTankPriority.registrationId,
      parent_think_tank_db_id: thinkTankPriority.parentThinkTankDbId,
      think_tank_db_id: thinkTankPriority.thinkTankDbId,
      priority: thinkTankPriority.priority
  }
}
}


export interface ThinkTankTableJSON extends BaseJSON {
  db_id: string;
  title: string;
  name: string;
  contents: ThinkTankContentJSON [];
}

export class ThinkTankTable extends BaseModel {
  dbId: string;
  title: string;
  name: string;
  contents: ThinkTankContent [];

  constructor(
      id: string,
      dbId: string,
      title: string,
      name: string,
      contents: ThinkTankContent []
  ) {
    super(id);

    this.dbId = dbId;
    this.title = title;
    this.name = name;
    this.contents = contents;
  }
}


export class ThinkTankFactory {
  /**
   *
   * @param json
   */
  fromJSON(json: ThinkTankTableJSON): ThinkTankTable {
    return new ThinkTankTable(
      json.id,
      json.db_id,
      json.title,
      json.name,
      json.contents.map((thinkTankContentJSON: ThinkTankContentJSON) => new ThinkTankContent(
        thinkTankContentJSON.id,
        thinkTankContentJSON.db_id,
        thinkTankContentJSON.title,
        thinkTankContentJSON.content_en,
        thinkTankContentJSON.submitted_by_registration_id,
        thinkTankContentJSON.active,
        thinkTankContentJSON.read_only,
        thinkTankContentJSON.status,
        thinkTankContentJSON.weight,
        thinkTankContentJSON.keywords.map((thinkTankKeywordJSON: ThinkTankKeywordJSON) => new ThinkTankKeyword(
          thinkTankKeywordJSON.id,
          thinkTankKeywordJSON.db_id,
          thinkTankKeywordJSON.title,
          thinkTankKeywordJSON.submitted_by_registration_id,
          thinkTankKeywordJSON.active,
          thinkTankKeywordJSON.read_only,
          thinkTankKeywordJSON.status
        ))
      ))
    );
  }

  toJSON(thinkTankTable: ThinkTankTable): ThinkTankTableJSON {
    return {
      id: thinkTankTable.id,
      db_id: thinkTankTable.dbId,
      title: thinkTankTable.title,
      name: thinkTankTable.name,
      contents: thinkTankTable.contents.map((thinkTankContent: ThinkTankContent) => ({
        id: thinkTankContent.id,
        db_id: thinkTankContent.dbId,
        title: thinkTankContent.title,
        content_en: thinkTankContent.contentEn,
        submitted_by_registration_id: thinkTankContent.submittedByRegistrationId,
        active: thinkTankContent.active,
        read_only: thinkTankContent.readOnly,
        status: thinkTankContent.status,
        weight: thinkTankContent.weight,
        keywords: thinkTankContent.keywords.map((thinkTankKeyword: ThinkTankKeyword) => ({
          id: thinkTankKeyword.id,
          db_id: thinkTankKeyword.dbId,
          title: thinkTankKeyword.title,
          submitted_by_registration_id: thinkTankKeyword.submittedByRegistrationId,
          active: thinkTankKeyword.active,
          read_only: thinkTankKeyword.readOnly,
          status: thinkTankKeyword.status
        }))
      }))
    };
  }
}

export enum AgendaElementType {
  ThinkTankRound = 0,
  DinnerRound,
  KeynoteRound,
  Other,
}

export class AgendaElement extends BaseModel {
  type: AgendaElementType;
  name: string;
  description: string;
  startTime: string;
  endTime: string;
  detailComponent?: any;

  constructor(
    id: string,
    type: AgendaElementType,
    name: string,
    description: string,
    startTime: string,
    endTime: string,
    detailComponent?: any
  ) {
    super(id);

    this.type = type;
    this.name = name;
    this.description = description;
    this.startTime = startTime;
    this.endTime = endTime;
    this.detailComponent = detailComponent;
  }
}

export interface SeatedRegistrationJSON extends BaseJSON {
  id: string;
  event_id: string;
  registration_id: string;
  registration_name: string;
  think_tank_table_id: string;
  think_tank_real_table_id: string;
  think_tank_name: string;
  dynamic_content_voting: [string];
  type: string;
  weight: string;
  round: number;
}

export class SeatedRegistration extends BaseModel {
  eventId: string;
  registrationID: string;
  registrationName: string;
  thinkTankId: string;
  thinkTankRealTableId: string;
  thinkTankName: string;
  dynamicContentVoting: [string];
  type: string;
  weight: string;
  round: number;

  constructor(
    id: string,
    eventId: string,
    registrationID: string,
    registrationName: string,
    thinkTankId: string,
    thinkTankRealTableId: string,
    thinkTankName: string,
    dynamicContentVoting: [string],
    type: string,
    weight: string,
    round: number
  ) {
    super(id);

    this.eventId = eventId;
    this.registrationID = registrationID;
    this.registrationName = registrationName;
    this.thinkTankId = thinkTankId;
    this.thinkTankRealTableId = thinkTankRealTableId;
    this.thinkTankName = thinkTankName;
    this.dynamicContentVoting = dynamicContentVoting;
    this.type = type;
    this.weight = weight;
    this.round = round;
  }
}

export class SeatedRegistrationFactory {
  fromJSON(json: SeatedRegistrationJSON): SeatedRegistration {
    return new SeatedRegistration(
      json.id,
      json.event_id,
      json.registration_id,
      json.registration_name,
      json.think_tank_table_id,
      json.think_tank_real_table_id,
      json.think_tank_name,
      json.dynamic_content_voting,
      json.type,
      json.weight,
      json.round
    );
  }

  toJSON(seatedRegistration: SeatedRegistration): SeatedRegistrationJSON {
    return {
      id: seatedRegistration.id,
      event_id: seatedRegistration.eventId,
      registration_id: seatedRegistration.registrationID,
      registration_name: seatedRegistration.registrationName,
      think_tank_table_id: seatedRegistration.thinkTankId,
      think_tank_real_table_id: seatedRegistration.thinkTankRealTableId,
      think_tank_name: seatedRegistration.thinkTankName,
      dynamic_content_voting: seatedRegistration.dynamicContentVoting,
      type: seatedRegistration.type,
      weight: seatedRegistration.weight,
      round: seatedRegistration.round,
    };
  }
}

export interface SeatedThinkTankJSON extends BaseJSON {
  id: string;
  event_id: string;
  think_tank_id: string;
  think_tank_name: string;
  think_tank_title: string;
  round: number;
  contents: ThinkTankContentJSON [];
}

export class SeatedThinkTank extends BaseModel {
  eventId: string;
  thinkTankId: string;
  thinkTankName: string;
  thinkTankTitle: string;
  round: number;
  contents: ThinkTankContent [];

  constructor(
    id: string,
    eventId: string,
    thinkTankId: string,
    thinkTankName: string,
    thinkTankTitle: string,
    round: number,
    contents: ThinkTankContent []
  ) {
    super(id);

    this.eventId = eventId;
    this.thinkTankId = thinkTankId;
    this.thinkTankName = thinkTankName;
    this.thinkTankTitle = thinkTankTitle;
    this.round = round;
    this.contents = contents;
  }
}

export class SeatedThinkTankFactory {
  fromJSON(json: SeatedThinkTankJSON): SeatedThinkTank {
    return new SeatedThinkTank(
      json.id,
      json.event_id,
      json.think_tank_id,
      json.think_tank_name,
      json.think_tank_title,
      json.round,
      json.contents.map((thinkTankContentJSON: ThinkTankContentJSON) => new ThinkTankContent(
          thinkTankContentJSON.id,
          thinkTankContentJSON.title,
          thinkTankContentJSON.language_code,
          thinkTankContentJSON.department,
          thinkTankContentJSON.topic,
          thinkTankContentJSON.sub_topic,
          thinkTankContentJSON.sub_sub_topic,
          thinkTankContentJSON.content1,
          thinkTankContentJSON.function_related_content1,
          thinkTankContentJSON.function_related_content2,
          thinkTankContentJSON.function_related_content3,
          thinkTankContentJSON.function_related_content4,
          thinkTankContentJSON.function_related_content5

      ))
    );
  }

  toJSON(seatedThinkTank: SeatedThinkTank): SeatedThinkTankJSON {
    return {
      id: seatedThinkTank.id,
      event_id: seatedThinkTank.eventId,
      think_tank_id: seatedThinkTank.thinkTankId,
      think_tank_name: seatedThinkTank.thinkTankName,
      think_tank_title: seatedThinkTank.thinkTankTitle,
      round: seatedThinkTank.round,
      contents: seatedThinkTank.contents.map((thinkTankContent: ThinkTankContent) => ({
        id: thinkTankContent.id,
        title: thinkTankContent.title,
        language_code: thinkTankContent.languageCode,
        department: thinkTankContent.department,
        topic: thinkTankContent.topic,
        sub_topic: thinkTankContent.subTopic,
        sub_sub_topic: thinkTankContent.subSubTopic,
        content1: thinkTankContent.content1,
        function_related_content1: thinkTankContent.functionRelatedContent1,
        function_related_content2: thinkTankContent.functionRelatedContent2,
        function_related_content3: thinkTankContent.functionRelatedContent3,
        function_related_content4: thinkTankContent.functionRelatedContent4,
        function_related_content5: thinkTankContent.functionRelatedContent5
      }))
    };
  }
}


export interface SeatedThinkTankRealTableJSON extends BaseJSON {
  id: string;
  event_id: string;
  think_tank_id: string;
  think_tank_name: string;
  think_tank_title: string;
  round_number: number;
  table_number: number;
  voting_time_end: string;
  contents: ThinkTankContentJSON [];
  dynamic_contents: ThinkTankDynamicContentJSON [];

}

export class SeatedThinkTankRealTable extends BaseModel {
  eventId: string;
  thinkTankId: string;
  thinkTankName: string;
  thinkTankTitle: string;
  roundNumber: number;
  tableNumber: number;
  votingTimeEnd: string;
  contents: ThinkTankContent [];
  dynamicContents: ThinkTankDynamicContent [];

  constructor(
    id: string,
    eventId: string,
    thinkTankId: string,
    thinkTankName: string,
    thinkTankTitle: string,
    roundNumber: number,
    tableNumber: number,
    votingTimeEnd: string,
    contents: ThinkTankContent [],
    dynamicContents: ThinkTankDynamicContent [],
  ) {
    super(id);

    this.eventId = eventId;
    this.thinkTankId = thinkTankId;
    this.thinkTankName = thinkTankName;
    this.thinkTankTitle = thinkTankTitle;
    this.roundNumber = roundNumber;
    this.tableNumber = tableNumber;
    this.votingTimeEnd = votingTimeEnd;
    this.contents = contents;
    this.dynamicContents = dynamicContents;
  }
}

export class SeatedThinkTankRealTableFactory {
  fromJSON(json: SeatedThinkTankRealTableJSON): SeatedThinkTankRealTable {
    return new SeatedThinkTankRealTable(
      json.id,
      json.event_id,
      json.think_tank_id,
      json.think_tank_name,
      json.think_tank_title,
      json.round_number,
      json.table_number,
      json.voting_time_end,
      json.contents.map((thinkTankContentJSON: ThinkTankContentJSON) => new ThinkTankContent(
          thinkTankContentJSON.id,
          thinkTankContentJSON.title,
          thinkTankContentJSON.language_code,
          thinkTankContentJSON.department,
          thinkTankContentJSON.topic,
          thinkTankContentJSON.sub_topic,
          thinkTankContentJSON.sub_sub_topic,
          thinkTankContentJSON.content1,
          thinkTankContentJSON.function_related_content1,
          thinkTankContentJSON.function_related_content2,
          thinkTankContentJSON.function_related_content3,
          thinkTankContentJSON.function_related_content4,
          thinkTankContentJSON.function_related_content5
      )),
      json.dynamic_contents.map((thinkTankDynamicContentJSON: ThinkTankDynamicContentJSON) => new ThinkTankDynamicContent(
         thinkTankDynamicContentJSON.id,
         thinkTankDynamicContentJSON.event_id,
         thinkTankDynamicContentJSON.registration_id,
         thinkTankDynamicContentJSON.real_table_id,
         thinkTankDynamicContentJSON.real_table_sf_id,
         thinkTankDynamicContentJSON.topic,
         thinkTankDynamicContentJSON.weight
       ))
    );
  }

  toJSON(seatedThinkTankRealTable: SeatedThinkTankRealTable): SeatedThinkTankRealTableJSON {
    return {
      id: seatedThinkTankRealTable.id,
      event_id: seatedThinkTankRealTable.eventId,
      think_tank_id: seatedThinkTankRealTable.thinkTankId,
      think_tank_name: seatedThinkTankRealTable.thinkTankName,
      think_tank_title: seatedThinkTankRealTable.thinkTankTitle,
      round_number: seatedThinkTankRealTable.roundNumber,
      table_number: seatedThinkTankRealTable.tableNumber,
      voting_time_end: seatedThinkTankRealTable.votingTimeEnd,
      contents: seatedThinkTankRealTable.contents.map((thinkTankContent: ThinkTankContent) => ({
        id: thinkTankContent.id,
        title: thinkTankContent.title,
        language_code: thinkTankContent.languageCode,
        department: thinkTankContent.department,
        topic: thinkTankContent.topic,
        sub_topic: thinkTankContent.subTopic,
        sub_sub_topic: thinkTankContent.subSubTopic,
        content1: thinkTankContent.content1,
        function_related_content1: thinkTankContent.functionRelatedContent1,
        function_related_content2: thinkTankContent.functionRelatedContent2,
        function_related_content3: thinkTankContent.functionRelatedContent3,
        function_related_content4: thinkTankContent.functionRelatedContent4,
        function_related_content5: thinkTankContent.functionRelatedContent5
      })),
      dynamic_contents: seatedThinkTankRealTable.dynamicContents.map((thinkTankDynamicContent: ThinkTankDynamicContent) => ({
        id: thinkTankDynamicContent.id,
        event_id: thinkTankDynamicContent.eventId,
        registration_id: thinkTankDynamicContent.registrationId,
        real_table_id: thinkTankDynamicContent.realTableId,
        real_table_sf_id: thinkTankDynamicContent.realTableSfId,
        topic: thinkTankDynamicContent.topic,
        weight: thinkTankDynamicContent.weight,
      }))
    };
  }
}





export interface PriorityJSON extends BaseJSON {
  id: string;
  main_registration: string;
  wish_seating_partner: string;
  priority: string;
}

export class Priority extends BaseModel {
  mainRegistration: string;
  wishSeatingPartner: string;
  priority: string;

  constructor(
    id: string,
    mainRegistration: string,
    wishSeatingPartner: string,
    priority: string
  ) {
    super(id);

    this.mainRegistration = mainRegistration;
    this.wishSeatingPartner = wishSeatingPartner;
    this.priority = priority;
  }
}

export class PriorityFactory {
  fromJSON(json: PriorityJSON): Priority {
    return new Priority(
      json.id,
      json.main_registration,
      json.wish_seating_partner,
      json.priority
    );
  }

  toJSON(priority: Priority): PriorityJSON {
    return {
      id: priority.id,
      main_registration: priority.mainRegistration,
      wish_seating_partner: priority.wishSeatingPartner,
      priority: priority.priority,
    };
  }
}

export interface Notification {
  id?: number;
  message: string;
  dismissed?: boolean;
  options: {
    variant?: "error" | "default" | "success" | "warning" | "info";
    onClose?: () => void;
  };
}

export interface DinnerSeatingJSON extends BaseJSON {
  event_id: string;
  registration_id: string;
  registration_name: string;
  registration_business_partner: string;
  type: string;
  side_of_table: number;
  seat_id: number;
  table_id: number;
  round: number;
  is_seated: boolean;
  seat_number: number;
}

export class DinnerSeating extends BaseModel {
  eventID: string;
  registrationID: string;
  registrationName: string;
  registrationBusinessPartner: string;
  round: number;
  type: string;
  sideOfTable: number;
  seatID: number;
  tableID: number;
  isSeated: boolean;
  seatNumber: number;

  constructor(
    id: string,
    eventID: string,
    registrationID: string,
    registrationName: string,
    registrationBusinessPartner: string,
    round: number,
    type: string,
    sideOfTable: number,
    seatID: number,
    tableID: number,
    isSeated: boolean,
    seatNumber: number,
  ) {
    super(id);

    this.eventID = eventID;
    this.registrationID = registrationID;
    this.registrationName = registrationName;
    this.registrationBusinessPartner = registrationBusinessPartner;
    this.round = round;
    this.type = type;
    this.sideOfTable = sideOfTable;
    this.seatID = seatID;
    this.tableID = tableID;
    this.isSeated = isSeated;
    this.seatNumber = seatNumber;
  }
}

export class DinnerSeatingFactory {
  fromJSON(json: DinnerSeatingJSON): DinnerSeating {
    return new DinnerSeating(
      json.id,
      json.event_id,
      json.registration_id,
      json.registration_name,
      json.registration_business_partner,
      json.round,
      json.type,
      json.side_of_table,
      json.seat_id,
      json.table_id,
      json.is_seated,
      json.seat_number
    );
  }

  toJSON(dinnerSeating: DinnerSeating): DinnerSeatingJSON {
    return {
      id: dinnerSeating.id,
      event_id: dinnerSeating.eventID,
      registration_id: dinnerSeating.registrationID,
      registration_name: dinnerSeating.registrationName,
      registration_business_partner: dinnerSeating.registrationBusinessPartner,
      type: dinnerSeating.type,
      side_of_table: dinnerSeating.sideOfTable,
      seat_id: dinnerSeating.seatID,
      table_id: dinnerSeating.tableID,
      round: dinnerSeating.round,
      is_seated: dinnerSeating.isSeated,
      seat_number: dinnerSeating.seatNumber,
    };
  }
}
