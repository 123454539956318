import React, {useEffect, useState} from "react";
import {v4 as uuid} from "uuid";
import {Box, Button, createStyles, makeStyles, Slide, Theme, Typography,} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {retrieveRegistrations, selectRegistration, selectRegistrations,} from "../registrations/registrationsSlice";
import {createPriorities, retrievePriorities, selectPriorities,} from "../priorities/prioritiesSlice";
import {Priority} from "../../models/models";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import Config from "../../config";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import {selectToken} from "../authentication/authenticationSlice";
import {useSnackbar} from "notistack";
import {retrieveEvent, selectEvent} from "../badge/eventSlice";
import {retrieveProfile, selectProfile} from "../registrations/registrationsSlice";
import {FirstLoginFlowDialog} from "../firstLoginFlow/FirstLoginFlowDialog"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: theme.palette.background.default,
      overflow: "auto",
    },
    row: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 20,
    },
    indicator: {
      height: 10,
      width: 10,
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      marginRight: 10,
    },
    name: {
      fontWeight: "bold",
    },
    linkedIn: {
      height: 20,
      width: 20,
    },
    validationModal: {
      position: "sticky",
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.palette.background.paper,
      borderRadius: 10,
      padding: 20,
    },
    validationError: {
      backgroundColor: theme.palette.secondary.main,
    },
    checkIcon: {
      marginLeft: 10,
      color: theme.palette.primary.main,
    },
    toggleGroup: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "10px !important",
      paddingLeft: 5,
      paddingTop: 5,
      paddingBottom: 5,
    },
    toggleButton: {
      border: "none",
      borderRadius: "8px !important",
      textTransform: "none",
      paddingLeft: 16,
      paddingRight: 16,
      marginRight: 5,
      "&.Mui-selected": {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      "&:hover": {
        backgroundColor: theme.palette.background.default,
      },
    },
  })
);

export const Priorities = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const authToken = useSelector(selectToken);

  const { enqueueSnackbar } = useSnackbar();

  // Business partners should not be selectable from the priority list
  const participants = useSelector(selectRegistrations).filter(
    (registration) => registration.type === "Executive Registration"
  );
  const registrations = useSelector(selectRegistrations);


  const registration = useSelector(
    selectRegistration(Config.getInstance().getRegistrationID())
  );

  const registrationPortalAccess = registration ? registration.portalAccess : "";

  const priorities = useSelector(selectPriorities);

  const profile = useSelector(
    selectProfile(Config.getInstance().getRegistrationID())
  );

  const event = useSelector(selectEvent);

  useEffect(() => {

    if (authToken) {

      dispatch(retrieveEvent(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveRegistrations(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveProfile(authToken,Config.getInstance().getEventID(),Config.getInstance().getRegistrationID()));

      if (registration?.id !== "") {
          dispatch(
              retrievePriorities(
                  authToken,
                  Config.getInstance().getEventID(),
                  registration.businessPartner
              )
          );
      }
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [authToken, registration?.businessPartner]);

  const [mutablePriorities, setMutablePriorities] = useState<{
    [key: string]: Priority;
  }>({});

  const votesPerPriority: { [key: string]: number } = Object.values(
    mutablePriorities
  )
    .filter((priority) => !!priority.priority)
    .reduce(
      (acc, priority) => ({
        ...acc,
        [priority.priority]: acc[priority.priority]
          ? acc[priority.priority] + 1
          : 1,
      }),
      {} as { [key: string]: number }
    );

  const thresholdExceeded = !Object.values(votesPerPriority).every(
    (value) => value <= 4
  );
  const thresholdMet =
    Object.values(votesPerPriority).length === 2 &&
    Object.values(votesPerPriority).every((value) => value === 4);

  useEffect(() => {
    if (Object.values(mutablePriorities).length <= 0) {
      setMutablePriorities(
        priorities.reduce(
          (acc: { [key: string]: Priority }, priority: Priority) => ({
            ...acc,
            [priority.wishSeatingPartner]: priority,
          }),
          {} as { [key: string]: Priority }
        )
      );
    }
  }, [priorities.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const savePriorities = (
    authToken: string,
    businessPartnerID: string,
    priorities: Priority[]
  ) => {
    dispatch(
      createPriorities(
        authToken,
        Config.getInstance().getEventID(),
        businessPartnerID,
        Object.values(priorities)
      )
    );
  };


  return (
    <Box className={classes.container}>
      <FirstLoginFlowDialog
           authToken={authToken || ""}
           registrations={registrations}
           profile={profile}
           event={event}
      />
      <Typography variant="h6" color="textPrimary" style={{ marginTop: 20 }}>
        Priorities
      </Typography>
      <Typography color="textSecondary" style={{ marginBottom: 20 }}>
        Please select 4 top priorities and 4 second priorities from the
        participant list below.
      </Typography>
      {participants
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((participant, index) => (
          <Box key={index} className={classes.row}>
            <Box>
              <Typography
                align="left"
                color="textPrimary"
                className={classes.name}
              >
                {participant.name}
              </Typography>
              <Typography align="left" color="textSecondary">
                {participant.title}, {participant.company}
              </Typography>
            </Box>
            <Box style={{ flex: 1 }} />
            <ToggleButtonGroup
              value={mutablePriorities[participant.id]?.priority}
              exclusive
              onChange={(_, value) => {
                const updatedPriorities = { ...mutablePriorities };

                if (updatedPriorities[participant.id]) {
                  if (value) {
                    // a priority has been selected
                    updatedPriorities[participant.id].priority = value;
                  } else {
                    // a previously selected priority has been deselected
                    delete updatedPriorities[participant.id];
                  }
                } else {
                  updatedPriorities[participant.id] = new Priority(
                    uuid(),
                    registration?.businessPartner || "",
                    participant.id,
                    value
                  );
                }
                setMutablePriorities(updatedPriorities);
              }}
              className={classes.toggleGroup}
              color="secondary"
            >
              <ToggleButton value="1" className={classes.toggleButton}>
                1
              </ToggleButton>
              <ToggleButton value="2" className={classes.toggleButton}>
                2
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        ))}
      <Slide
        direction="up"
        in={
          Object.values(mutablePriorities).filter(
            (priority) => !!priority.priority
          ).length > 0
        }
        mountOnEnter
        unmountOnExit
      >
        <Box
          className={`${classes.validationModal} ${
            thresholdExceeded ? classes.validationError : undefined
          }`}
        >
          {thresholdExceeded && (
            <Typography color="textPrimary" style={{ marginBottom: 20 }}>
              You can only select 4 participants per priority
            </Typography>
          )}
          <Box
            style={{ display: "flex", flexDirection: "row", marginBottom: 20 }}
          >
            <Box style={{ flex: 1 }}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6" color="textPrimary">
                  {
                    Object.values(mutablePriorities).filter(
                      (priority) => priority.priority === "1"
                    ).length
                  }{" "}
                  / {4}
                </Typography>
                {votesPerPriority["1"] === 4 && (
                  <CheckCircleRoundedIcon className={classes.checkIcon} />
                )}
              </Box>
              <Typography color="textSecondary">Priority 1</Typography>
            </Box>
            <Box style={{ flex: 1 }}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6" color="textPrimary">
                  {
                    Object.values(mutablePriorities).filter(
                      (priority) => priority.priority === "2"
                    ).length
                  }{" "}
                  / {4}
                </Typography>
                {votesPerPriority["2"] === 4 && (
                  <CheckCircleRoundedIcon className={classes.checkIcon} />
                )}
              </Box>
              <Typography color="textSecondary">Priority 2</Typography>
            </Box>
          </Box>
          <Button
            disabled={thresholdExceeded}
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              if (registrationPortalAccess==="Closed") {
                enqueueSnackbar("The priorization portal has been closed. Please contact your Network Circle manager for further instructions", {
                  variant: "warning",
                });
              }
              else if (authToken && registration && registration.businessPartner) {
                savePriorities(
                  authToken,
                  registration.businessPartner,
                  Object.values(mutablePriorities)
                );

                enqueueSnackbar("Priorities submitted successfully.", {
                  variant: "success",
                });
              }
            }}
          >
            Save
          </Button>
        </Box>
      </Slide>
    </Box>
  );
};
