import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk, RootState} from "../../app/store";
import {DinnerSeating, DinnerSeatingFactory, DinnerSeatingJSON,} from "../../models/models";
import Config from "../../config";

interface DinnerSeatingOption {
  tableCount: number;
  seatsPerTableCount: number;
}

interface EventState {
  isFetching: boolean;
  seatings: DinnerSeatingJSON[];
  options: DinnerSeatingOption[];
}

const initialState: EventState = {
  isFetching: false,
  seatings: [],
  options: [],
};

export const dinnerSeatingSlice = createSlice({
  name: "dinnerSeating",
  initialState,
  reducers: {
    storeFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    storeDinnerSeating: (state, action: PayloadAction<DinnerSeating[]>) => {
      const dinnerSeatingFactory = new DinnerSeatingFactory();

      state.seatings = action.payload.map((dinnerSeating) =>
        dinnerSeatingFactory.toJSON(dinnerSeating)
      );
    },
    storeDinnerOptions: (
      state,
      action: PayloadAction<DinnerSeatingOption[]>
    ) => {
      state.options = action.payload;
    },
    purgeStore: (state) => {
      state.isFetching = false;
      state.seatings = [];
    },
  },
});

export const { storeDinnerSeating, storeDinnerOptions, storeFetching, purgeStore } =
  dinnerSeatingSlice.actions;

export const retrieveDinnerSeating =
  (authToken: string, eventID: string): AppThunk =>
  async (dispatch) => {
    try {
      const headers: Headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${authToken}`);

      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/events/${eventID}/dinner_seating/`;

      dispatch(storeFetching(true));

      fetch(url, {
        method: "GET",
        headers: headers,
      })
        .then((response) => response.json())
        .then((data) => {
          dispatch(purgeStore());

          dispatch(
            storeDinnerSeating(
              data["seatings"].map((dinnerSeatingJSON: DinnerSeatingJSON) =>
                new DinnerSeatingFactory().fromJSON(dinnerSeatingJSON)
              )
            )
          );

          dispatch(
            storeDinnerOptions(
              data["options"].map(
                (optionJSON: any): DinnerSeatingOption => ({
                  tableCount: optionJSON["table_count"],
                  seatsPerTableCount: optionJSON["seats_per_table_count"],
                })
              )
            )
          );
          dispatch(storeFetching(false));
        })
        .catch((error) => {
          console.error(error);
          dispatch(storeFetching(false));
        });
    } catch (error) {
      dispatch(storeFetching(false));
      console.error(error);
    }
  };

export const selectDinnerSeatings = (state: RootState) => {
  const dinnerSeatingFactory = new DinnerSeatingFactory();

  return state.dinnerSeatingStore.seatings.map((dinnerSeatingJSON) =>
    dinnerSeatingFactory.fromJSON(dinnerSeatingJSON)
  );
};

export const selectDinnerSeating =
  (registrationID: string, round?: number) => (state: RootState) => {
    const filteredDinnerSeatings = state.dinnerSeatingStore.seatings.filter(
      (dinnerSeatingJSON) =>
        dinnerSeatingJSON.registration_id === registrationID &&
        (round === undefined || dinnerSeatingJSON.round === round)
    );

    return filteredDinnerSeatings.length > 0
      ? new DinnerSeatingFactory().fromJSON(filteredDinnerSeatings[0])
      : undefined;
  };

export const selectOptions = (state: RootState) =>
  state.dinnerSeatingStore.options;


export const selectDinnerSeatingIsFetching = (state: RootState) =>
  state.dinnerSeatingStore.isFetching;

export default dinnerSeatingSlice.reducer;
