import React, {useEffect, useState, useMemo} from "react";
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography,
    LinearProgress,
    IconButton,
} from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";
import {useDispatch, useSelector} from "react-redux";
import {selectToken} from "../authentication/authenticationSlice";
import Config from "../../config";
import {retrieveRegistrations, selectRegistration, selectRegistrations,} from "../registrations/registrationsSlice";
import {retrieveThinkTankSeating, selectSeatedThinkTankRealTables, selectSeatedRegistrations} from "../badge/thinkTankSeatingSlice";
import {SeatedThinkTankRealTable} from "../../models/models";
import {retrieveEvent, selectEvent} from "../badge/eventSlice";
import {DynamicThinkTanksVoting} from "./DynamicThinkTanksVoting";
import { socket } from '../../utils/webSocket';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: theme.palette.background.default,
      overflow: "auto",
    },
    name: {
      fontWeight: "bold",
    },
  })
);

export const DynamicThinkTanks = () => {

  const [selectedRoundInit, setSelectedRoundInit] = useState<number>(1);

  const classes = useStyles();
  const dispatch = useDispatch();

  const registration = useSelector(selectRegistration(Config.getInstance().getRegistrationID()));
  const registrations = useSelector(selectRegistrations);
  const seatedRegistrations = useSelector(selectSeatedRegistrations);
  const seatedThinkTankRealTables = useSelector(selectSeatedThinkTankRealTables);

  const event = useSelector(selectEvent);

  const authToken = useSelector(selectToken);

  useEffect(() => {
    if (authToken) {
      dispatch(retrieveEvent(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveRegistrations(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveThinkTankSeating(authToken, Config.getInstance().getEventID()));
    }
    if(!socket.connected){
      socket.connect();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleReload = () => {
    if (authToken) {
      dispatch(retrieveEvent(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveRegistrations(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveThinkTankSeating(authToken, Config.getInstance().getEventID()));
    }
    if(!socket.connected){
      socket.connect();
    }
  };

  console.log(socket);


  const assignedThinkTankRealTables: SeatedThinkTankRealTable[] = useMemo(() => {
    //get think tank real table of for the current registration in this round
    //note: need to work with arrays since single objects might be undefined
    const userSeating = seatedRegistrations.filter(
          (registration) =>
              registration.registrationID === Config.getInstance().getRegistrationID()
          );

    const thinkTankRealTableId = userSeating.map(
      (seatedRegistration) => seatedRegistration.thinkTankRealTableId
    );

    const thinkTankRealTables = seatedThinkTankRealTables.filter((seatedThinkTankRealTable) =>
      thinkTankRealTableId.includes(seatedThinkTankRealTable.thinkTankId)
    );

    return thinkTankRealTables;
  }, [seatedThinkTankRealTables.length, seatedRegistrations.length]); // eslint-disable-line react-hooks/exhaustive-deps


  const userSeating: SeatedRegistrations[] = useMemo(() => {

    //get the user seating
    //note: need to work with arrays since single objects might be undefined
    const userSeating = seatedRegistrations.filter(
          (registration) =>
              registration.registrationID === Config.getInstance().getRegistrationID()
          );

    return userSeating;
  }, [seatedThinkTankRealTables.length, seatedRegistrations.length]); // eslint-disable-line react-hooks/exhaustive-deps


//reload all data if round changed
  const sendDataToParent = (selectedRound: number) => {
    setSelectedRoundInit(selectedRound);
    if (authToken) {
      dispatch(retrieveEvent(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveRegistrations(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveThinkTankSeating(authToken, Config.getInstance().getEventID()));
    }
    if(!socket.connected){
      socket.connect();
    }
  };


  return (
    <Box className={classes.container}>

     <Box
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box style={{ flex: 1 }}>
          <Typography
            variant="h6"
            color="textPrimary"
            style={{ marginTop: 20, marginBottom: 20 }}
          >
             Live Event
          </Typography>
        </Box>

        <Box>
           <IconButton onClick={() => handleReload()}>
              <ReplayIcon/>
           </IconButton>
        </Box>
      </Box>



      {assignedThinkTankRealTables.length === 0 &&
       userSeating.length === 0 && <LinearProgress color="primary" />}

      {assignedThinkTankRealTables.length > 0 &&
       userSeating.length > 0 &&
          <DynamicThinkTanksVoting
            authToken={authToken}
            assignedThinkTankRealTables={assignedThinkTankRealTables}
            seatedRegistrations={userSeating}
            selectedRoundInit={selectedRoundInit}
            sendDataToParent={sendDataToParent}
          />
        }



    </Box>
  );
};
