import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    Slide,
    Toolbar,
    createStyles,
    makeStyles,
    withStyles,
    TextField,
    IconButton,
    Theme,
    Typography,
    LinearProgress,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import Config from "../../config";
import {ThinkTankDynamicContent, ThinkTankDynamicContentFactory} from "../../models/models";
import {SeatedThinkTankRealTable} from "../../models/models";
import {useSnackbar} from "notistack";
import Grid from '@material-ui/core/Grid';
import {CloseRounded, DragIndicatorRounded, InfoRounded} from "@material-ui/icons";
import {animated, config, to, useSprings} from "@react-spring/web";
import {useDrag} from "react-use-gesture";
import { socket } from '../../utils/webSocket';
import {CountdownComponent} from "./CountdownComponent";
import {utcDateToLocalDate} from "./../../utils/dateFunctions";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: theme.palette.background.default,
      overflow: "auto",
    },
    gridItem: {
//      backgroundColor: "red",
      width: "50%",
    },
    ratingScrollContainer: {
      paddingBottom: 20,
    },
    ratingRowContainer: {
      overflow: "visible",
      position: "relative",
      userSelect: "none",
    },
    ratingRankContainer: {
      left: 0,
      right: 0,
      top: -4,
      height: 278,
      border: `2px dashed ${theme.palette.primary.main}`,
      borderRadius: 10,
      position: "absolute",
    },
    row: {
      display: "flex",
      alignItems: "center",
      marginBottom: 20,
      marginLeft: 20
    },
    indicator: {
      height: 10,
      width: 10,
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      marginRight: 10,
    },
    topicElementOuter: {
      flex: "1",
      display: "flex",
      alignItems: "center",
      color: theme.palette.text.secondary,
      background: theme.palette.background.paper,
      marginLeft: 10,
      marginRight: 10,
      height: 60,
      left: 0,
      right: 0,
      borderRadius: 10,
      overflow: "hidden",
    },
    topicElementInner: {
      display: "flex",
      marginLeft: 10,
      overflow: "hidden",
      minWidth: 0,
      width: "100%",
      flexDirection: "column",
      wordBreak: "break-all",
    },
    topicElementTitle: {
      display: "flex",
      alignItems: "top",
      height: 40,
      wordBreak: "break-all",
    },
    animatedRow: {
      position: "absolute",
      marginLeft: 4,
      marginRight: 4,
      height: 60,
      left: 0,
      right: 0,
      overflow: "visible",
      pointerEvents: "auto",
      transformOrigin: "50% 50% 0px",
      borderRadius: 10,
      color: theme.palette.text.secondary,
      background: theme.palette.background.paper,
      display: "flex",
      alignItems: "center",
    },
    rankIndicator: {
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      height: 60,
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: "10px 0px 0px 10px",
    },
    selectionContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    roundSelectionContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 10,
      marginBottom: 10,
    },
    toggleGroup: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "10px !important",
      paddingLeft: 5,
      paddingTop: 5,
      paddingBottom: 5,
    },
    toggleButton: {
      border: "none",
      borderRadius: "8px !important",
      textTransform: "none",
      paddingLeft: 16,
      paddingRight: 16,
      marginRight: 5,
      "&.Mui-selected": {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      "&:hover": {
        backgroundColor: theme.palette.background.default,
      },
    },
    icon: {
      color: theme.palette.text.primary,
    },
    name: {
      fontWeight: "bold",
    },
    spacer: {
      flex: 1,
    },
  })
);

export interface DynamicThinkTanksVotingProps {
  authToken: string;
  assignedThinkTankRealTables: SeatedThinkTankRealTable[];
  seatedRegistrations: [];
  selectedRoundInit: number;
  sendDataToParent: (arg1: number) => void;
};



export const DynamicThinkTanksVoting: React.FC<DynamicThinkTanksVotingProps> = ({authToken,
                                         assignedThinkTankRealTables,
                                         seatedRegistrations,
                                         selectedRoundInit,
                                         sendDataToParent,
                                       }: DynamicThinkTanksVotingProps) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [selectedRound, setSelectedRound] = useState(selectedRoundInit);

  const [responseError, setResponseError] = useState({errorFound: false,
                                                      errorMessage: ""});

  const [updatedThinkTankRealTables, setUpdatedThinkTankRealTables] = useState(assignedThinkTankRealTables);
  //set room id
  const [roomId, setRoomId] = useState(updatedThinkTankRealTables.filter((thisRealTable) => thisRealTable.roundNumber === selectedRound)[0]?.id || "");
  const [roomSfId, setRoomSfId] = useState(updatedThinkTankRealTables.filter((thisRealTable) => thisRealTable.roundNumber === selectedRound)[0]?.thinkTankId || "");

  const [allDynamicContents,setAllDynamicContents] = useState(updatedThinkTankRealTables.filter((thisRealTable) => thisRealTable.roundNumber === selectedRound)[0]?.dynamicContents.flat());
  const [selectedDynamicContent, setSelectedDynamicContent] = useState(null);
  const [seatedRegistration, setSeatedRegistration] = useState(seatedRegistrations.filter((thisSeatedRegistration) => thisSeatedRegistration.round === selectedRound-1)[0] || null);

  const thinkTankDynamicContentFactory = new ThinkTankDynamicContentFactory();

  const [ownThinkTankDynamicContentFirst, setOwnThinkTankDynamicContentFirst] =
              useState(allDynamicContents.filter((content) => content.registrationId === Config.getInstance().getRegistrationID())[0] ||
              thinkTankDynamicContentFactory.init(
                Config.getInstance().getEventID(),
                Config.getInstance().getRegistrationID(),
                roomId,
                roomSfId,
              )
              );
   const [ownThinkTankDynamicContentSecond, setOwnThinkTankDynamicContentSecond] =
               useState(allDynamicContents.filter((content) => content.registrationId === Config.getInstance().getRegistrationID())[1] ||
              thinkTankDynamicContentFactory.init(
                Config.getInstance().getEventID(),
                Config.getInstance().getRegistrationID(),
                roomId,
                roomSfId,
              )
              );

  const [ownDynamicContentSubmitted, setOwnDynamicContentSubmitted] = useState(
    allDynamicContents.filter((content) => content.registrationId === Config.getInstance().getRegistrationID() && content.id !== "").length > 0 ? true : false
  )


//initialize socket
  useEffect(() => {


    //all functions to be defined on certain messages
    const onPublishMessage = (data) => {
    }

    const onPublishVoteMessage = (data) => {
    }

    const onPublishMessageResponse = (data) => {
      setAllDynamicContents(
        previousState => (
          [...previousState, data.dynamic_content_1, data.dynamic_content_2]
        )
        );

      setCurrentOrder(
        previousState => (
          [...previousState, previousState.length, previousState.length+1]
        )
      );
    }

    const onPublishVoteMessageResponse = (data) => {
      setAllDynamicContents(
        previousState => {
          const prios = data.prios;
          return [...previousState].map((previousDynamicThinkTank) => {
            const updatedDynamicThinkTank = prios.find((element) => element.id === previousDynamicThinkTank.id);
            if(updatedDynamicThinkTank){
              previousDynamicThinkTank.weight = updatedDynamicThinkTank.weight;
//              return updatedDynamicThinkTank;
            }
            return previousDynamicThinkTank;
          });
        }
        );
    }

    const onPublishMessageResponseError = (data) => {
      if(data['error_found'] === true) {
          enqueueSnackbar(data['error_message'], {
              variant: "error",
          });
      } else {
        enqueueSnackbar(data['error_message'], {
            variant: "success",
        });
      }

      setResponseError({
              ...responseError,
              errorFound: true,
       }
      )
    }

    const onJoinRoom = (data) => {
    }
    const onJoinRoomResponse = (data) => {
      setRoomId(data['room_id']);
    }

    const onLeaveRoom = (data) => {
    }
    const onLeaveRoomResponse = (data) => {
      setRoomId("");
    }

    const onOpenVotingResponse = (data) => {
      const selectedRound = data['round_number']
      const newVotingEndTime = new Date(data['voting_end_time']); //here, we leave it as utc date
      const previousRealTables = [...updatedThinkTankRealTables];
      const newRealTables = previousRealTables.map(
            (thisRealTable) => {
              if(thisRealTable.roundNumber === selectedRound){
                thisRealTable.votingTimeEnd = newVotingEndTime;
                return thisRealTable
              } else {
                return thisRealTable
              }
            }
      )
      setEndVotingDatetime(utcDateToLocalDate(newVotingEndTime));
      setCountDown(utcDateToLocalDate(newVotingEndTime).getTime()-new Date().getTime());
      setUpdatedThinkTankRealTables(newRealTables);
    };

     socket.on("publish_message", onPublishMessage);
     socket.on("publish_vote_message", onPublishVoteMessage);
     socket.on("publish_message_response", onPublishMessageResponse);
     socket.on("publish_vote_message_response", onPublishVoteMessageResponse);
     socket.on("publish_message_response_error", onPublishMessageResponseError);
     socket.on("join_room", onJoinRoom);
     socket.on("join_room_response", onJoinRoomResponse);
     socket.on("leave_room", onLeaveRoom);
     socket.on("leave_room_response", onLeaveRoomResponse);
     socket.on("open_voting_response", onOpenVotingResponse);

     socket.emit("join_room", {'room_id': roomId});

     return () => {
       socket.off("publish_message", onPublishMessage);
       socket.off("publish_vote_message", onPublishVoteMessage);
       socket.off("publish_message_response", onPublishMessageResponse)
       socket.off("publish_vote_message_response", onPublishVoteMessageResponse);
       socket.off("publish_message_response_error", onPublishMessageResponseError);
       socket.off("join_room", onJoinRoom);
       socket.off("join_room_response", onJoinRoomResponse);
       socket.off("leave_room", onLeaveRoom);
       socket.off("leave_room_response", onLeaveRoomResponse);
       socket.off("open_voting_response", onOpenVotingResponse);
     }

}, []);

//save submitted keywords via websocket
  const saveThinkTankDynamicContent = (
    authToken: string,
    eventId: string,
    registrationId: string,
    ownThinkTankDynamicContentFirst: ThinkTankDynamicContent,
    ownThinkTankDynamicContentSecond: ThinkTankDynamicContent,
  ) => {

    const dynamicContentJSON1 = thinkTankDynamicContentFactory.toJSON(ownThinkTankDynamicContentFirst);
    const dynamicContentJSON2 = thinkTankDynamicContentFactory.toJSON(ownThinkTankDynamicContentSecond);

    socket.emit("publish_message", {'dynamic_content_1': dynamicContentJSON1,
                                    'dynamic_content_2': dynamicContentJSON2});

    setOwnDynamicContentSubmitted(true);

  };


//save submitted keywords via websocket
  const voteThinkTankDynamicContent = (
    authToken: string,
    eventId: string,
    registrationId: string,
    votedThinkTanks: [],
  ) => {

    const votedThinkTanksJSON = votedThinkTanks.map((votedThinkTank) => thinkTankDynamicContentFactory.toJSON(votedThinkTank));

    if(socket.connected){
      socket.emit("publish_vote_message", {'submitter_registration_id': Config.getInstance().getRegistrationID(),
                                                 'real_table_id': roomId,
                                                 'real_table_sf_id': roomSfId,
                                                 'prios': votedThinkTanksJSON});
  } else {
      enqueueSnackbar("Something went wrong and you are not connected to the websocket. Please reload the page and try again.", {
          variant: "error",
    });
  }
  };

  const handleClickOpenDynamicContentDetails = (thinkTankDynamicContent: ThinkTankDynamicContent) => {
    setSelectedDynamicContent(thinkTankDynamicContent);
  };

  const handleClickCloseDynamicContentDetails = () => {
    setSelectedDynamicContent(null);
  };


//now, all functions for dragging/rating the dynamic topics
  const fn =
    (
      order: number[],
      active = false,
      originalIndex = 0,
      currentIndex = 0,
      y = 0
    ) =>
    (index: number) =>
      active && index === originalIndex
        ? {
            y: currentIndex * 70 + y,
            scale: 1.05,
            zIndex: 1,
            shadow: 10,
            immediate: (key: string) => key === "zIndex",
            config: (key: string) =>
              key === "y" ? config.stiff : config.default,
          }
        : {
            y: order.indexOf(index) * 70,
            scale: 1,
            zIndex: 0,
            shadow: 0,
            immediate: false,
          };

  const clamp = (x: number, min: number, max: number) =>
    Math.max(min, Math.min(x, max));

  const equal = (x: number[], y: number[]) => {
    if (x.length !== y.length) {
      return false;
    }

    return x.every((value: number, index: number) => value === y[index]);
  };

  const bind = useDrag(
    ({ args: [originalIndex], active, movement: [, y] }) => {
//      const currentIndex = order.current.indexOf(originalIndex);
      const currentIndex = currentOrder.indexOf(originalIndex);
      const currentRow = clamp(
        Math.round((currentIndex * 54 + y) / 54),
        0,
        allDynamicContents.length - 1
      );
      const newOrder = swap(currentOrder, currentIndex, currentRow);
      api.start(fn(newOrder, active, originalIndex, currentIndex, y));
      if (!active) {
        if (!equal(currentOrder, newOrder)) {
          setCurrentOrder(newOrder);//order.current = newOrder;
          setTimeout(() => {
            setTargetOrder(currentOrder);
//            setTargetOrder(order.current);
          }, 500);
        }
      }
    },
    {
      preventDefault: true,
      preventScroll: true,
      preventScrollAxis: "y",
    }
  );

  const swap = (array: any[], moveIndex: number, toIndex: number) => {
    const item = array[moveIndex];
    const length = array.length;
    const diff = moveIndex - toIndex;

    if (diff > 0) {
      // move left
      return [
        ...array.slice(0, toIndex),
        item,
        ...array.slice(toIndex, moveIndex),
        ...array.slice(moveIndex + 1, length),
      ];
    } else if (diff < 0) {
      // move right
      const targetIndex = toIndex + 1;
      return [
        ...array.slice(0, moveIndex),
        ...array.slice(moveIndex + 1, targetIndex),
        item,
        ...array.slice(targetIndex, length),
      ];
    }
    return array;
  };

  const receiveInitialOrder = (() => {
    if(seatedRegistration.dynamicContentVoting.length > 0) {
      const votedIndices = seatedRegistration.dynamicContentVoting.map((thisVotingId) => {
          const thisIndex = allDynamicContents.findIndex((thisDynamicContent) => thisDynamicContent.id === thisVotingId);
          return thisIndex;
      }
    )
      //note: map returns undefined if matches are found
      //then filter out undefined values
      const nonVotedIndices = allDynamicContents.map((thisDynamicContent, index) => {
        if(votedIndices.includes(index)){
          return undefined;
        } else {
        return index;
      }
    }).filter(x => x || x===0); //explicitly account for the zeroth index as well
      return votedIndices.concat(nonVotedIndices);
    }
    return allDynamicContents.map((_, index) => index);
  });


  const [targetOrder, setTargetOrder] = useState([]);
  const [currentOrder, setCurrentOrder] = useState(receiveInitialOrder());

  const [springs, api] = useSprings(allDynamicContents.length, fn(currentOrder), [
    currentOrder,
  ]);

  const calcTotalWeight = () => {
    if(allDynamicContents.length === 0){
      return 0.
    } else{
      return [...allDynamicContents].map((thisDynamicContent) => thisDynamicContent.weight).reduce((a,b) => a+b);
    }
  }

  const changeSelectedRound = (newSelectedRound) => {
    if(!socket.connected){
      socket.connect();
    }
    const newRoomId = updatedThinkTankRealTables.filter((thisRealTable) => thisRealTable.roundNumber === newSelectedRound)[0]?.id || "";
    const newRoomSfId = updatedThinkTankRealTables.filter((thisRealTable) => thisRealTable.roundNumber === newSelectedRound)[0]?.thinkTankId || ""
    const newEndVotingDatetime = new Date(updatedThinkTankRealTables.filter((thisRealTable) => thisRealTable.roundNumber === newSelectedRound)[0]?.votingTimeEnd) || new Date();
    setEndVotingDatetime(newEndVotingDatetime);
    setCountDown(newEndVotingDatetime.getTime() - new Date().getTime());

    socket.emit("leave_room", {'room_id': roomId});
    socket.emit("join_room", {'room_id': newRoomId});
    setSelectedRound(newSelectedRound);
    setRoomId(newRoomId);

    setRoomSfId(newRoomSfId);
    setAllDynamicContents(updatedThinkTankRealTables.filter((thisRealTable) => thisRealTable.roundNumber === newSelectedRound)[0]?.dynamicContents.flat());
    setSeatedRegistration(seatedRegistrations.filter((thisSeatedRegistration) => thisSeatedRegistration.round === newSelectedRound-1)[0] || null);

    const dynamicContents = updatedThinkTankRealTables.filter((thisRealTable) => thisRealTable.roundNumber === newSelectedRound)[0]?.dynamicContents.flat() || [];
    const seatedRegistration = seatedRegistrations.filter((thisSeatedRegistration) => thisSeatedRegistration.round === newSelectedRound-1)[0] || null;

    let currentOrder = [];

    if(seatedRegistration && seatedRegistration.dynamicContentVoting.length > 0) {
      const votedIndices = seatedRegistration.dynamicContentVoting.map((thisVotingId) => {
          const thisIndex = dynamicContents.findIndex((thisDynamicContent) => thisDynamicContent.id === thisVotingId);
          return thisIndex;
      }
    )
      const nonVotedIndices = dynamicContents.map((thisDynamicContent, index) => {
          if(votedIndices.includes(index)){
            return undefined;
          } else {
          return index;
        }
      }).filter(x => x || x===0);
        currentOrder =  votedIndices.concat(nonVotedIndices);
    } else {
      currentOrder = dynamicContents.map((_, index) => index);
    }

    setCurrentOrder(currentOrder);
    setTargetOrder(currentOrder);

    setOwnThinkTankDynamicContentFirst(
              dynamicContents.filter((content) => content.registrationId === Config.getInstance().getRegistrationID())[0] ||
              thinkTankDynamicContentFactory.init(
                Config.getInstance().getEventID(),
                Config.getInstance().getRegistrationID(),
                newRoomId,
                newRoomSfId,
              )
            );
    setOwnThinkTankDynamicContentSecond(
              dynamicContents.filter((content) => content.registrationId === Config.getInstance().getRegistrationID())[1] ||
              thinkTankDynamicContentFactory.init(
                Config.getInstance().getEventID(),
                Config.getInstance().getRegistrationID(),
                newRoomId,
                newRoomSfId,
              )
            );
    setOwnDynamicContentSubmitted(dynamicContents.filter((content) => content.registrationId === Config.getInstance().getRegistrationID() && content.id !== "").length > 0 ? true : false);

    sendDataToParent(newSelectedRound);

  }

  //countdown in milliseconds
  const [endVotingDatetime, setEndVotingDatetime] = useState(() => {
    const thisDate=new Date(updatedThinkTankRealTables.filter((thisRealTable) => thisRealTable.roundNumber === selectedRound)[0].votingTimeEnd);
    if(thisDate){
      return utcDateToLocalDate(thisDate);
    }
    return new Date();
  });
  const [countDown, setCountDown] = useState(endVotingDatetime.getTime() - new Date().getTime());

  useEffect(() => {
    if(countDown >= 0) {
    const interval = setInterval(() => {
      setCountDown(endVotingDatetime.getTime() - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }
  }, [countDown]);

  return (


    <Box>
    <Box className={classes.row}>
        <CountdownComponent
           countDown = {countDown}
        />
    </Box>
      <Box className={classes.selectionContainer}>
        <Box className={classes.roundSelectionContainer}>

          <Box className={classes.spacer} />
          <Box sx={{width: 200}}>
              <ToggleButtonGroup
                color="primary"
                value={selectedRound}
                exclusive
                onChange={(_, value: number) => {
                  changeSelectedRound(value);
              }}
              size="small"
              className={classes.toggleGroup}
            >
              <ToggleButton value={1} className={classes.toggleButton}>
                Round 1
              </ToggleButton>
              <ToggleButton value={2} className={classes.toggleButton}>
                Round 2
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      </Box>

      <Box style={{width: '100%',
                  flexDirection: 'row',
                  justifyContent: "space-between",
                  display: "flex",
                }}>
        <Box style={{flex: 1}}>
          <TextField
            style = {{width: '100%'}}
            label="First Keyword (max. 32)"
            disabled={ownDynamicContentSubmitted}
            variant="filled"
            color="primary"
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              maxLength: 32,
            }}
            value={ownThinkTankDynamicContentFirst?.topic || ""}
            onChange={(event) =>
                setOwnThinkTankDynamicContentFirst({
                    ...ownThinkTankDynamicContentFirst,
                    topic : event.target.value,
                })
            }
          />
        </Box>

        <Box style={{width: '1%'}} />

        <Box style={{flex: 1}}>
          <TextField
            style = {{width: '100%'}}
            label="Second Keyword (max. 32)"
            disabled={ownDynamicContentSubmitted}
            variant="filled"
            color="primary"
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              maxLength: 32,
            }}
            value={ownThinkTankDynamicContentSecond?.topic || ""}
            onChange={(event) =>
                setOwnThinkTankDynamicContentSecond({
                    ...ownThinkTankDynamicContentSecond,
                    topic: event.target.value,
                })
            }
          />
        </Box>

      </Box>

      <Box style={{ height: 20 }} />
      <Button
        style={{width: "100%"}}
        disabled={ownThinkTankDynamicContentFirst?.topic === "" || ownThinkTankDynamicContentSecond?.topic === "" || ownDynamicContentSubmitted || countDown <= 0}
        color="primary"
        variant="contained"
              onClick={() =>
                authToken &&
                saveThinkTankDynamicContent(
                  authToken,
                  Config.getInstance().getEventID(),
                  Config.getInstance().getRegistrationID(),
                  ownThinkTankDynamicContentFirst,
                  ownThinkTankDynamicContentSecond,
                )}
      >
        Submit your Keywords
      </Button>


      <Box style={{ height: 20 }} />

      <Grid container>
         <Grid item xs={6}>
            <Typography variant="h6" color="textPrimary" style={{ marginTop: 20 }}>
              Available Topics
            </Typography>
            <Typography color="textSecondary" style={{ marginBottom: 20 }}>
              Please order the topics you are interested in the most from top to
              bottom by dragging the rows.
            </Typography>

      <Button
        disabled={countDown <= 0}
        style={{width: "100%"}}
        color="primary"
        variant="contained"
              onClick={() =>
                authToken &&
                voteThinkTankDynamicContent(
                  authToken,
                  Config.getInstance().getEventID(),
                  Config.getInstance().getRegistrationID(),
                  currentOrder.map((index) => allDynamicContents[index]),
                )}
      >
        Submit your Votes
      </Button>
      </Grid>


      <Grid item xs={6}>

              <Typography variant="h6" color="textPrimary" style={{ marginTop: 20 }}>
                Table Rating
              </Typography>
              <Typography color="textSecondary" style={{ marginBottom: 20 }}>
                Please find below the vote distribution
              </Typography>

      </Grid>



      <Grid item xs={6}>

      <Box style={{ height: 20 }} />

              <Box className={classes.ratingRowContainer}
                        style={{
            height: allDynamicContents.length * 60 + (allDynamicContents.length - 1) * 10 + 20,
          }}>

          <Box className={classes.ratingRankContainer} />


          {springs.map(({ zIndex, shadow, y, scale }, i) => {
//            const rank = order.current.indexOf(i);
            const rank = currentOrder.indexOf(i);

            return (
              <animated.div
                {...bind(i)}
                className={classes.animatedRow}
                key={i}
                style={{
                  zIndex,
                  boxShadow: shadow.to(
                    (s: number) =>
                      `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
                  ),
                  transform: to(
                    [y, scale],
                    (y, s) => `translate3d(0,${y}px,0) scale(${s})`
                  ),
                }}
              >
                <Box className={classes.topicElementOuter}>
                  {rank < 4 && (
                    <Box className={classes.rankIndicator}>
                      <Typography>{rank + 1}</Typography>
                    </Box>
                  )}

                  <Box className={classes.topicElementInner}>
                    <Box className={classes.topicElementTitle}>
                      <Typography
                        align="left"
                        color="textPrimary"
                      >
                       {allDynamicContents[i].topic}
                      </Typography>
                    </Box>
                  </Box>
                  <Box style={{ flex: 1 }}>
                  <IconButton
                    onClick={() => handleClickOpenDynamicContentDetails(allDynamicContents[i])}
                  >
                    <InfoRounded
                      style={{fontSize: 17}}
                      className={classes.icon}
                    />
                  </IconButton>
                  <DragIndicatorRounded
                    style={{fontSize: 17}}
                    className={classes.icon}
                  />
                  </Box>
                </Box>
              </animated.div>
            );
          })}

            </Box>

</Grid>


         <Grid item xs={6}>
      <Box style={{ height: 20 }} />

          {[...allDynamicContents].sort((thisDynamicContentFirst, thisDynamicContentSecond) => {
            if (thisDynamicContentFirst.weight > thisDynamicContentSecond.weight){
              return -1;
            } else if (thisDynamicContentFirst.weight < thisDynamicContentSecond.weight){
              return 1;
            } else {
              return 0;
            }
          })
            .map((thisDynamicContent, index) => {
            return (
              <Box>
                <Box className={classes.topicElementOuter}>
                  {index < 4 && (
                    <Box className={classes.rankIndicator}>
                      <Typography>{index + 1}</Typography>
                    </Box>
                  )}
                  <Box className={classes.topicElementInner}>
                    <LinearProgress variant="determinate" color="primary" value={calcTotalWeight() === 0 ? 0 : thisDynamicContent.weight/calcTotalWeight()*100} />
                    <Box className={classes.topicElementTitle}>
                      <Typography
                        align="left"
                        color="textPrimary"
                      >
                        {thisDynamicContent.topic}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box style={{height: 10}}>
                </Box>
                </Box>
            );
          })}

           </Grid>
        </Grid>


      <Dialog
          open={Boolean(selectedDynamicContent)}
          onClose={handleClickCloseDynamicContentDetails}
          fullWidth={true}
          maxWidth={"lg"}
          TransitionComponent={Slide}
          TransitionProps={{direction: "up"}}
          PaperProps={{
            className: classes.dialog
          }}
      >
          <Toolbar className={classes.toolBar} style={{padding: 10}}>
            <Typography style={{ marginLeft: 2, flex: 1 }} variant="h6" component="div">
              {selectedDynamicContent?.topic}
            </Typography>
            <IconButton
                edge="start"
                color="inherit"
                onClick={handleClickCloseDynamicContentDetails}
                aria-label="close"
            >
              <CloseRounded />
            </IconButton>
          </Toolbar>
        <Box style={{padding: 20}}>
        {selectedDynamicContent?.subTopics?.map(subContent => (
            <Box className={classes.subTopic}>
              <Typography color="textSecondary">
                {subContent}
              </Typography>
            </Box>
        ))}
        </Box>
      </Dialog>



    </Box>
  );
};
