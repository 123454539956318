import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk, RootState} from "../../app/store";
import {Priority, PriorityFactory, PriorityJSON} from "../../models/models";
import Config from "../../config";

interface EventState {
  isFetching: boolean;
  priorities: PriorityJSON[];
}

const initialState: EventState = {
  isFetching: false,
  priorities: [],
};

export const prioritiesSlice = createSlice({
  name: "priorities",
  initialState,
  reducers: {
    storeFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    storePriorities: (state, action: PayloadAction<Priority[]>) => {
      const priorityFactory = new PriorityFactory();

      state.priorities = action.payload.map((priority) =>
        priorityFactory.toJSON(priority)
      );
    },
    purgeStore: (state) => {
      state.priorities = [];
    },
  },
});

export const { storePriorities, purgeStore } = prioritiesSlice.actions;

export const retrievePriorities =
  (authToken: string, eventID: string, businessPartnerID: string): AppThunk =>
  async (dispatch) => {
    try {
      const headers: Headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${authToken}`);

      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/events/${eventID}/business_partners/${businessPartnerID}/priorities/`;

      const priorityFactory = new PriorityFactory();

      fetch(url, {
        method: "GET",
        headers: headers,
      })
        .then((response) => response.json())
        .then((data) => {
          // make sure the returned data is actually array-shaped
          if (!data || !Array.isArray(data)) {
            return;
          }

          dispatch(purgeStore());

          dispatch(
            storePriorities(
              data.map((priorityJSON) => priorityFactory.fromJSON(priorityJSON))
            )
          );
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

export const createPriorities =
  (
    authToken: string,
    eventID: string,
    businessPartnerID: string,
    priorities: Priority[]
  ): AppThunk =>
  async (dispatch) => {
    try {
      const headers: Headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${authToken}`);

      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/events/${eventID}/business_partners/${businessPartnerID}/priorities/`;

      const priorityFactory = new PriorityFactory();

      fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(
          priorities.map((priority) => priorityFactory.toJSON(priority))
        ),
      })
        .then((response) => response.json())
        .then((data) => {
          dispatch(purgeStore());
          dispatch(storePriorities(priorities));
        });
    } catch (error) {
      console.error(error);
    }
  };

export const selectPriorities = (state: RootState) => {
  const priorityFactory = new PriorityFactory();

  return state.priorityStore.priorities.map((priorityJSON) =>
    priorityFactory.fromJSON(priorityJSON)
  );
};

export default prioritiesSlice.reducer;
