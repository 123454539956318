import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    createStyles,
    IconButton,
    makeStyles,
    Theme,
    LinearProgress,
    Typography
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import Config from "../../config";
import {retrieveThinkTanks, selectThinkTanks, selectThinkTanksIsFetching} from "./thinkTankSlice";
import {retrieveThinkTankPriorities, selectThinkTankPriorities, selectThinkTankPrioritiesIsFetching} from "./thinkTankPrioritiesSlice";
import {DragIndicatorRounded, InfoRounded} from "@material-ui/icons";
import {animated, config, to, useSprings} from "@react-spring/web";
import {useDrag} from "react-use-gesture";
import {Registration, RegistrationFactory, ThinkTankTable, ThinkTankFactory, ThinkTankPriority, ThinkTankPriorityFactory} from "../../models/models";
import {retrieveRegistrations, selectRegistration, selectRegistrations, updateRegistration,} from "../registrations/registrationsSlice";
import {selectToken} from "../authentication/authenticationSlice";
import {useSnackbar} from "notistack";
import {retrieveProfile, selectProfile} from "../registrations/registrationsSlice";
import {retrieveEvent, selectEvent} from "../badge/eventSlice";
import {FirstLoginFlowDialog} from "../firstLoginFlow/FirstLoginFlowDialog";
import {ThinkTankTableScreen} from "./ThinkTankTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: theme.palette.background.default,
      overflow: "auto",
    },
    scrollContainer: {
      paddingBottom: 20,
    },
    row: {
      flex: 1,
      display: "flex",
      alignItems: "center",
    },
    indicator: {
      height: 10,
      width: 10,
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      marginRight: 10,
    },
    name: {
      fontWeight: "bold",
    },
    description: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "pre",
      minWidth: 0,
    },
    icon: {
      color: theme.palette.text.primary,
    },
    animatedRow: {
      position: "absolute",
      height: 60,
      left: 0,
      right: 0,
      overflow: "visible",
      pointerEvents: "auto",
      transformOrigin: "50% 50% 0px",
      borderRadius: 10,
      color: theme.palette.text.secondary,
      background: theme.palette.background.paper,
      display: "flex",
      alignItems: "center",
    },
    rowContainer: {
      overflow: "visible",
      position: "relative",
      userSelect: "none",
    },
    rankIndicator: {
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      height: 60,
      paddingLeft: 20,
      paddingRight: 20,
      borderRadius: "10px 0px 0px 10px",
    },
    bookedIndicator: {
      backgroundColor: "gold",
      color: "gold",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      height: 60,
      paddingLeft: 20,
      paddingRight: 20,
      borderRadius: "10px 0px 0px 10px",
    },
    rankContainer: {
      left: -6,
      right: -6,
      top: -6,
      height: 278,
      border: `2px dashed ${theme.palette.primary.main}`,
      borderRadius: 10,
      position: "absolute",
    },
    rankContainerBooked: {
      left: -6,
      right: -6,
      top: 65,
      height: 277,
      border: `2px dashed ${theme.palette.primary.main}`,
      borderRadius: 10,
      position: "absolute",
    },
    bookedContainer: {
      left: -6,
      right: -6,
      top: -6,
      height: 67,
      border: `2px dashed gold`,
      borderRadius: 10,
      position: "absolute",
    },
    dialog: {
      backgroundColor: theme.palette.background.default,
    },
    toolBar: {
      border: `1px solid ${theme.palette.background.paper}`,
    },
    thinkTankContent: {
      padding: 10,
      borderRadius: 10,
      backgroundColor: theme.palette.background.paper,
      marginBottom: 20,
    },
    thinkTankKeyword: {
      padding: 2,
      borderRadius: 10,
      backgroundColor: 'blue',
      marginBottom: 10,
      marginRight: 30,
      marginLeft: 30
    }
  })
);

export const ThinkTanks = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const authToken = useSelector(selectToken);

  const thinkTanks = useSelector(selectThinkTanks);

  const thinkTankPriorities = useSelector(selectThinkTankPriorities);

  const thinkTanksIsFetching = useSelector(selectThinkTanksIsFetching);
  const thinkTankPrioritiesIsFetching = useSelector(selectThinkTankPrioritiesIsFetching);

  const participant = useSelector(selectRegistration(Config.getInstance().getRegistrationID()));

  const registrations = useSelector(selectRegistrations);


  const profile = useSelector(selectProfile(Config.getInstance().getRegistrationID()));

  const event = useSelector(selectEvent);

  useEffect(() => {
    if (authToken) {
      dispatch(retrieveRegistrations(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveThinkTanks(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveThinkTankPriorities(authToken, Config.getInstance().getEventID(), Config.getInstance().getRegistrationID()));
      dispatch(retrieveEvent(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveProfile(authToken,Config.getInstance().getEventID(),Config.getInstance().getRegistrationID()));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const [mutableThinkTanks, setMutableThinkTanks] = useState<ThinkTankTable[]>(thinkTanks);
  //sub-priorities
  const [mutableThinkTankPriorities, setMutableThinkTankPriorities] = useState<ThinkTankPriorities[]>(thinkTankPriorities);

  const [isUpdatingThinkTank, setIsUpdatingThinkTank] = useState<boolean>(false);
  const [isUpdatingThinkTankPriority, setIsUpdatingThinkTankPriority] = useState<boolean>(false);
  const [targetOrder, setTargetOrder] = useState<number[]>([]);

  const [selectedThinkTankTable, setSelectedThinkTankTable] = React.useState<ThinkTankTable | null>(null);

  //sub-priorities for selected think tank table
  const [selectedThinkTankPriorities, setSelectedThinkTankPriorities] = React.useState<ThinkTankPriority[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const fn =
    (
      order: number[],
      active = false,
      originalIndex = 0,
      currentIndex = 0,
      y = 0
    ) =>
    (index: number) =>
      active && index === originalIndex
        ? {
            y: currentIndex * 70 + y,
            scale: 1.05,
            zIndex: 1,
            shadow: 10,
            immediate: (key: string) => key === "zIndex",
            config: (key: string) =>
              key === "y" ? config.stiff : config.default,
          }
        : {
            y: order.indexOf(index) * 70,
            scale: 1,
            zIndex: 0,
            shadow: 0,
            immediate: false,
          };

  const order = useRef(mutableThinkTanks.map((_, index) => index));
  const [springs, api] = useSprings(mutableThinkTanks.length, fn(order.current), [
    order.current.length,
  ]);

  const clamp = (x: number, min: number, max: number) =>
    Math.max(min, Math.min(x, max));

  const equal = (x: number[], y: number[]) => {
    if (x.length !== y.length) {
      return false;
    }

    return x.every((value: number, index: number) => value === y[index]);
  };

  const bind = useDrag(
    ({ args: [originalIndex], active, movement: [, y] }) => {
      const currentIndex = order.current.indexOf(originalIndex);
      const currentRow = clamp(
        Math.round((currentIndex * 54 + y) / 54),
        0,
        mutableThinkTanks.length - 1
      );
      const newOrder = swap(order.current, currentIndex, currentRow);
      api.start(fn(newOrder, active, originalIndex, currentIndex, y));
      if (!active) {
        if (!equal(order.current, newOrder)) {
          order.current = newOrder;
          setTimeout(() => {
            setTargetOrder(order.current);
          }, 500);
        }
      }
    },
    {
      preventDefault: true,
      preventScroll: true,
      preventScrollAxis: "y",
    }
  );

  const swap = (array: any[], moveIndex: number, toIndex: number) => {
    const item = array[moveIndex];
    const length = array.length;
    const diff = moveIndex - toIndex;

    if (diff > 0) {
      // move left
      return [
        ...array.slice(0, toIndex),
        item,
        ...array.slice(toIndex, moveIndex),
        ...array.slice(moveIndex + 1, length),
      ];
    } else if (diff < 0) {
      // move right
      const targetIndex = toIndex + 1;
      return [
        ...array.slice(0, moveIndex),
        ...array.slice(moveIndex + 1, targetIndex),
        item,
        ...array.slice(targetIndex, length),
      ];
    }
    return array;
  };

  const participantID = participant ? participant.id : "";
  const participantPortalAccess = participant ? participant.portalAccess : "";

  useEffect(() => {
    setMutableThinkTanks(thinkTanks);
    if (participant && mutableThinkTanks.length > 0) {
      order.current = mutableThinkTanks.map((_, index) => index);

      let sortedThinkTanks = [...mutableThinkTanks];

      if (participant.bookedThinkTankId !== ""){

        //check if a booked think tank is existing
        const fromIndex = sortedThinkTanks.findIndex(
          (thinkTank) => thinkTank.id === participant.bookedThinkTankId
        );
        if (fromIndex >= 0) {
          //set booked think tank to first entry
           const toIndex = 0;
           sortedThinkTanks = swap(sortedThinkTanks, fromIndex, toIndex);
           order.current = swap(order.current, fromIndex, toIndex);
          //set priorities to the other entries 1-5
           participant.priorities.forEach((priority, toIndex) => {
             const fromIndex = sortedThinkTanks.findIndex(
               (thinkTank) => thinkTank.id === priority
             );
             if (fromIndex >= 0) {
               sortedThinkTanks = swap(sortedThinkTanks, fromIndex, toIndex);
               order.current = swap(order.current, fromIndex, toIndex+1);
             };
           });
      };
    }
      else {

      participant.priorities.forEach((priority, toIndex) => {
        const fromIndex = sortedThinkTanks.findIndex(
          (thinkTank) => thinkTank.id === priority
        );

        if (fromIndex >= 0) {
          sortedThinkTanks = swap(sortedThinkTanks, fromIndex, toIndex);
          order.current = swap(order.current, fromIndex, toIndex);
        }
      });
    }

      setTargetOrder(order.current);
    }
  }, [thinkTanks.length, participantID]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    setMutableThinkTankPriorities(thinkTankPriorities);
  }, [thinkTankPriorities.length, participantID]);

  const updatePriorities = (
    authToken: string,
    registration: Registration,
    thinkTanks: ThinkTankTable[]
  ) => {
    const registrationFactory = new RegistrationFactory();

    const registrationClone = registrationFactory.fromJSON(
      registrationFactory.toJSON(registration)
    );

    if(participant?.bookedThinkTankSuggestion ||
       participant?.bookedThinkTankSelection) {
       registrationClone.priorities = thinkTanks
         .slice(1, 5)
         .map((thinkTank) => thinkTank.id);
       registrationClone.bookedThinkTankId = thinkTanks[0].id;
     }
    else {
    registrationClone.priorities = thinkTanks
      .slice(0, 4)
      .map((thinkTank) => thinkTank.id);

    };

    dispatch(
      updateRegistration(
        authToken,
        Config.getInstance().getEventID(),
        Config.getInstance().getRegistrationID(),
        registrationClone
      )
    );
  };


  const updateThinkTankContents = (
    authToken: string,
    registration: Registration,
    updatedThinkTank: ThinkTankTable
  ): AppThunk =>
  async (dispatch) => {
    try {
      setIsUpdatingThinkTank(true);

      const headers: Headers = new Headers();
      const eventId = Config.getInstance().getEventID();
      const registrationId = Config.getInstance().getRegistrationID();
      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${authToken}`);

      const thinkTankTableFactory = new ThinkTankFactory();
      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/events/${eventId}/registrations/${registrationId}/update_think_tank_contents/`;
      fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(
          thinkTankTableFactory.toJSON(updatedThinkTank)
        )
      })
        .then((response) => response.json())
        .then((data) => {
          const thisThinkTankTable = thinkTankTableFactory.fromJSON(data);
          let thinkTankTablesTemp = [...mutableThinkTanks];
          const indxTable = thinkTankTablesTemp.map(thinkTankTable => thinkTankTable.id).indexOf(thisThinkTankTable.id);
          thinkTankTablesTemp[indxTable] = thisThinkTankTable;
          setMutableThinkTanks(thinkTankTablesTemp);
          setIsUpdatingThinkTank(false);
        })
        .catch((error) => {
          console.error(error);
          setIsUpdatingThinkTank(false);
        });
    } catch (error) {
      console.error(error);
    }
};

  const updateThinkTankPriorities = (
    authToken: string,
    thinkTankPriorities: ThinkTankPriority[]
  ): AppThunk =>
  async (dispatch) => {
    try {
      setIsUpdatingThinkTankPriority(true);

      const headers: Headers = new Headers();
      const eventId = Config.getInstance().getEventID();
      const registrationId = Config.getInstance().getRegistrationID();
      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${authToken}`);

      const thinkTankPriorityFactory = new ThinkTankPriorityFactory();
      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/events/${eventId}/${registrationId}/think_tank_priorities/`;
      fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(
          thinkTankPriorities.map((thinkTankPriority) => thinkTankPriorityFactory.toJSON(thinkTankPriority))
        )
      })
        .then((response) => response.json())
        .then(() => setIsUpdatingThinkTankPriority(false))
        .catch((error) => {
          console.error(error);
          setIsUpdatingThinkTankPriority(false);
        });
    } catch (error) {
      console.error(error);
    }

};


  const openThinkTankTable = (thinkTankTable: ThinkTankTable, thinkTankPriorities: ThinkTankPriority[]) => {
    setSelectedThinkTankTable(thinkTankTable);
    setSelectedThinkTankPriorities(thinkTankPriorities.filter((thisPriority) => thisPriority.parentThinkTankDbId === thinkTankTable.dbId))
  };

  const closeThinkTankTable = (thinkTankTable: ThinkTankTable, priorityOrder: number[], priorityOrderHasChanged: boolean) => {
    //number of updated/created contents
    const nUpdatedContents = thinkTankTable.contents.filter(content => content.status > 1).length;
    if(nUpdatedContents > 0){
      //send thinkTankTable to backend and update local state from response
      dispatch(updateThinkTankContents(authToken, participant, thinkTankTable));
    }

    if(priorityOrderHasChanged){

      //create array for new priorities of selected think tank
      const updatedPriorities = priorityOrder.map((thisIndex, counter) => {
        //limit to top 4 prio only
        if(counter <4){
              const thisContent = thinkTankTable.contents[thisIndex];
              const newPriority = new ThinkTankPriority(
                      null,
                      Config.getInstance().getRegistrationID(),
                      thinkTankTable.dbId,
                      thisContent.dbId,
                      counter + 1
              );
              return newPriority;
        }
      }).filter((thisPriority) => thisPriority !== undefined);

      //remove all priorities of selected think tank
      let mutableThinkTankPrioritiesTemp = mutableThinkTankPriorities.filter((thisPriority) => thisPriority.parentThinkTankDbId !== thinkTankTable.dbId || thisPriority.thinkTankDbId === null);

      //add new priorities of selected think tank to global array of all priorities
      mutableThinkTankPrioritiesTemp = mutableThinkTankPrioritiesTemp.concat(updatedPriorities);
      setMutableThinkTankPriorities(mutableThinkTankPrioritiesTemp);
    }

    setSelectedThinkTankTable(null);
    setSelectedThinkTankPriorities([]);

  };

  const isLoading = thinkTanksIsFetching || isUpdatingThinkTank || thinkTankPrioritiesIsFetching || isUpdatingThinkTankPriority;

  return (
    <Box className={classes.container}>
      {isLoading && <LinearProgress color="primary" />}

      <FirstLoginFlowDialog
           authToken={authToken || ""}
           registrations={registrations}
           profile={profile}
           event={event}
      />

      {!isLoading &&
      <Box className={classes.scrollContainer}>
        <Typography variant="h6" color="textPrimary" style={{ marginTop: 20 }}>
          Available Topics
        </Typography>
        <Typography color="textSecondary" style={{ marginBottom: 20 }}>
          Please order the topics you are interested in the most from top to
          bottom by dragging the rows.
        </Typography>
        <Box
          className={classes.rowContainer}
          style={{
            height: mutableThinkTanks.length * 60 + (mutableThinkTanks.length - 1) * 10 + 20,
          }}
        >
          {(participant?.bookedThinkTankSuggestion ||
            participant?.bookedThinkTankSelection)
            &&
          <Box className={classes.bookedContainer} />}
          {(participant?.bookedThinkTankSuggestion ||
            participant?.bookedThinkTankSelection)
            &&
          <Box className={classes.rankContainerBooked} />
          }
          {(!participant?.bookedThinkTankSuggestion &&
            !participant?.bookedThinkTankSelection)
            &&
          <Box className={classes.rankContainer} />
          }

          {springs.map(({ zIndex, shadow, y, scale }, i) => {
            const rank = order.current.indexOf(i);

            return (
              <animated.div
                {...bind(i)}
                className={classes.animatedRow}
                key={i}
                style={{
                  zIndex,
                  boxShadow: shadow.to(
                    (s: number) =>
                      `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
                  ),
                  transform: to(
                    [y, scale],
                    (y, s) => `translate3d(0,${y}px,0) scale(${s})`
                  ),
                }}
              >
                <Box className={classes.row}>
                  {(participant?.bookedThinkTankSuggestion ||
                    participant?.bookedThinkTankSelection)
                    && rank === 0 && (
                    <Box className={classes.bookedIndicator}>
                      <Typography> * </Typography>
                    </Box>
                  )}
                  {(participant?.bookedThinkTankSuggestion ||
                    participant?.bookedThinkTankSelection)
                    && rank > 0 && rank < 5 && (
                    <Box className={classes.rankIndicator}>
                      <Typography>{rank}</Typography>
                    </Box>
                  )}
                  {(!participant?.bookedThinkTankSuggestion &&
                    !participant?.bookedThinkTankSelection)
                    && rank < 4 && (
                    <Box className={classes.rankIndicator}>
                      <Typography>{rank + 1}</Typography>
                    </Box>
                  )}

                  <Box
                    style={{
                      marginLeft: 10,
                      overflow: "hidden",
                      minWidth: 0,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      align="left"
                      color="textPrimary"
                      className={classes.name}
                    >
                      {mutableThinkTanks[i].title}
                    </Typography>
                  </Box>
                  <Box style={{ flex: 1 }} />
                  <IconButton onClick={() => openThinkTankTable(mutableThinkTanks[i], mutableThinkTankPriorities)}>
                    <InfoRounded/>
                  </IconButton>
                  <DragIndicatorRounded
                    className={classes.icon}
                    style={{ marginLeft: 10, marginRight: 10 }}
                  />
                </Box>
              </animated.div>
            );
          })}
        </Box>


        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={() => {
            if (participantPortalAccess==="Closed") {
              enqueueSnackbar("The priorization portal has been closed. Please contact your Network Circle manager for further instructions", {
                variant: "warning",
              });
            }
            else if (authToken && participant) {
              updatePriorities(
                authToken,
                participant,
                order.current.map((item) => mutableThinkTanks[item])
              );
              dispatch(updateThinkTankPriorities(authToken,
                                        mutableThinkTankPriorities));

              enqueueSnackbar("Priorities submitted successfully.", {
                variant: "success",
              });
            }
          }}
        >
          Save
        </Button>
      </Box>
    }

      {selectedThinkTankTable &&
        <ThinkTankTableScreen
           thinkTankTable={selectedThinkTankTable}
           thinkTankPriorities={selectedThinkTankPriorities}
           closeDialog={closeThinkTankTable}
        />
      }

  </Box>
  );
};
