import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk, RootState} from "../../app/store";
import {
    SeatedRegistration,
    SeatedRegistrationFactory,
    SeatedRegistrationJSON,
    SeatedThinkTank,
    SeatedThinkTankFactory,
    SeatedThinkTankJSON,
    SeatedThinkTankRealTable,
    SeatedThinkTankRealTableFactory,
    SeatedThinkTankRealTableJSON,

} from "../../models/models";
import Config from "../../config";

interface EventState {
  isFetching: boolean;
  seatedRegistrations: SeatedRegistrationJSON[];
  seatedThinkTanks: SeatedThinkTankJSON[];
  seatedThinkTankRealTables: SeatedThinkTankRealTableJSON[];
}

const initialState: EventState = {
  isFetching: false,
  seatedRegistrations: [],
  seatedThinkTanks: [],
  seatedThinkTankRealTables: [],
};

export const thinkThankSeatingSlice = createSlice({
  name: "thinkTankSeating",
  initialState,
  reducers: {
    storeFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    storeSeatedRegistrations: (
      state,
      action: PayloadAction<SeatedRegistration[]>
    ) => {
      const seatedRegistrationFactory = new SeatedRegistrationFactory();

      state.seatedRegistrations = action.payload.map((seatedRegistration) =>
        seatedRegistrationFactory.toJSON(seatedRegistration)
      );
    },
    storeSeatedThinkTanks: (
      state,
      action: PayloadAction<SeatedThinkTank[]>
    ) => {
      const seatedThinkTankFactory = new SeatedThinkTankFactory();

      state.seatedThinkTanks = action.payload.map((seatedThinkTank) =>
        seatedThinkTankFactory.toJSON(seatedThinkTank)
      );
    },

    storeSeatedThinkTankRealTables: (
      state,
      action: PayloadAction<SeatedThinkTankRealTable[]>
    ) => {
      const seatedThinkTankRealTableFactory = new SeatedThinkTankRealTableFactory();

      state.seatedThinkTankRealTables = action.payload.map((seatedThinkTankRealTable) =>
        seatedThinkTankRealTableFactory.toJSON(seatedThinkTankRealTable)
      );
    },

    purgeStore: (state) => {
      state.isFetching = false;
      state.seatedRegistrations = [];
      state.seatedThinkTanks = [];
      state.seatedThinkTankRealTables = [];
    },
  },
});

export const { storeFetching, storeSeatedRegistrations, storeSeatedThinkTanks, storeSeatedThinkTankRealTables, purgeStore } =
  thinkThankSeatingSlice.actions;

export const retrieveThinkTankSeating =
  (authToken: string, eventID: string): AppThunk =>
  async (dispatch) => {
    try {
      const headers: Headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${authToken}`);

      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/events/${eventID}/think_tank_seating/`;

      dispatch(storeFetching(true));

      fetch(url, {
        method: "GET",
        headers: headers,
      })
        .then((response) => response.json())
        .then((data) => {
          dispatch(purgeStore());

          dispatch(
            storeSeatedRegistrations(
              data["registrations"].map(
                (seatedRegistrationJson: SeatedRegistrationJSON) =>
                  new SeatedRegistrationFactory().fromJSON(
                    seatedRegistrationJson
                  )
              )
            )
          );

          dispatch(
            storeSeatedThinkTanks(
              data["think_tanks"].map(
                (seatedThinkTankJson: SeatedThinkTankJSON) =>
                  new SeatedThinkTankFactory().fromJSON(seatedThinkTankJson)
              )
            )
          );

          dispatch(
            storeSeatedThinkTankRealTables(
              data["think_tank_real_tables"].map(
                (seatedThinkTankRealTableJson: SeatedThinkTankRealTableJSON) =>
                  new SeatedThinkTankRealTableFactory().fromJSON(seatedThinkTankRealTableJson)
              )
            )
          );

         dispatch(storeFetching(false));

        })
        .catch((error) => {
          console.log(error);
          dispatch(storeFetching(false));
        });
    } catch (error) {
      dispatch(storeFetching(false));
      console.error(error);
    }
  };

export const selectSeatedRegistrations = (state: RootState) => {
  const seatedRegistationFactory = new SeatedRegistrationFactory();

  return state.thinkTankRealTableSeatingStore.seatedRegistrations.map(
    (seatedRegistrationJSON) =>
      seatedRegistationFactory.fromJSON(seatedRegistrationJSON)
  );

/*  return state.thinkTankSeatingStore.seatedRegistrations.map(
    (seatedRegistrationJSON) =>
      seatedRegistationFactory.fromJSON(seatedRegistrationJSON)
  );*/
};



export const selectSeatedThinkTanks = (state: RootState) => {
  const seatedThinkTankFactory = new SeatedThinkTankFactory();

  return state.thinkTankSeatingStore.seatedThinkTanks.map(
    (seatedThinkTankJSON) =>
      seatedThinkTankFactory.fromJSON(seatedThinkTankJSON)
  );
};

export const selectSeatedThinkTankRealTables = (state: RootState) => {
  const seatedThinkTankRealTableFactory = new SeatedThinkTankRealTableFactory();

  return state.thinkTankRealTableSeatingStore.seatedThinkTankRealTables.map(
    (seatedThinkTankRealTableJSON) =>
      seatedThinkTankRealTableFactory.fromJSON(seatedThinkTankRealTableJSON)
  );
};

export const selectThinkTankSeatingIsFetching = (state: RootState) =>
  state.thinkTankSeatingStore.isFetching;


export default thinkThankSeatingSlice.reducer;
