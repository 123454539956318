import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: theme.palette.background.default,
      overflow: "auto",
    },
    gridItem: {
//      backgroundColor: "red",
      width: "50%",
    },
    ratingScrollContainer: {
      paddingBottom: 20,
    },
    ratingRowContainer: {
      overflow: "visible",
      position: "relative",
      userSelect: "none",
    },
    ratingRankContainer: {
      left: -4,
      right: -4,
      top: -4,
      height: 278,
      border: `2px dashed ${theme.palette.primary.main}`,
      borderRadius: 10,
      position: "absolute",
    },
    row: {
      display: "flex",
      alignItems: "center",
      marginBottom: 20,
      marginLeft: 20
    },
    indicator: {
      height: 10,
      width: 10,
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      marginRight: 10,
    },
    topicElement: {
      flex: 1,
      display: "flex",
      alignItems: "center",
    },
    animatedRow: {
      position: "absolute",
      marginLeft: 10,
      marginRight: 10,
      height: 60,
      left: 0,
      right: 0,
      overflow: "visible",
      pointerEvents: "auto",
      transformOrigin: "50% 50% 0px",
      borderRadius: 10,
      color: theme.palette.text.secondary,
      background: theme.palette.background.paper,
      display: "flex",
      alignItems: "center",
    },
    rankIndicator: {
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      height: 60,
      paddingLeft: 20,
      paddingRight: 20,
      borderRadius: "10px 0px 0px 10px",
    },

    selectionContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    roundSelectionContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 10,
      marginBottom: 10,
    },
    toggleGroup: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "10px !important",
      paddingLeft: 5,
      paddingTop: 5,
      paddingBottom: 5,
    },
    toggleButton: {
      border: "none",
      borderRadius: "8px !important",
      textTransform: "none",
      paddingLeft: 16,
      paddingRight: 16,
      marginRight: 5,
      "&.Mui-selected": {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      "&:hover": {
        backgroundColor: theme.palette.background.default,
      },
    },
    icon: {
      color: theme.palette.text.primary,
    },
    name: {
      fontWeight: "bold",
    },
  })
);


export interface CountdownProps {
  countDown: number;
};


export const CountdownComponent: React.FC<CountdownProps> = (
  {countDown,}: CountdownProps) => {

  const getCountdownValues = (countDown) => {
    const hours = Math.floor(countDown/1000/60/60);
    const minutes = Math.floor((countDown % (1000*60*60)) / 1000/60 );
    const seconds = Math.floor((countDown % (1000*60)) / 1000 );
    return {hours: hours, minutes: minutes, seconds: seconds}
  }

  return (
    <Box
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          textAlign: "center"
        }}
    >
        <Box style={{ flex: 1 }}>
        {countDown >= 0 ?
          <>
          <Typography
            variant="h2"
            color="textPrimary"
            style={{ marginTop: 20, marginBottom: 20 }}
          >
          {getCountdownValues(countDown).hours < 10 ? 0 : ""}
            {getCountdownValues(countDown).hours}
             :
          {getCountdownValues(countDown).minutes < 10 ? 0 : ""}
             {getCountdownValues(countDown).minutes}
             :
          {getCountdownValues(countDown).seconds < 10 ? 0 : ""}
             {getCountdownValues(countDown).seconds}
          </Typography>
          </>
          :
          <Typography
            variant="h2"
            color="textPrimary"
            style={{ marginTop: 20, marginBottom: 20 }}
          >
          VOTING CLOSED
          </Typography>
        }

        </Box>

    </Box>
  );
};
